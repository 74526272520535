export default [
    {
        path: "/account",
        name: "account",
        redirect: "/account/login",
        component: () => import("../views/account/Index.vue"),
        meta: { title: "账号中心 - Account" },
        children: [
            {
                path: "login",
                name: "account-login",
                component: () => import("../views/account/LoginV2.vue"),
                meta: { title: "登录 - Sign in" },
            },
        ],
    },
];
