import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/es",
        name: "es",
        redirect: "/es/stat",
        component: () => import("../views/erp/Index.vue"),
        meta: {
            title: "薪资管理 - Salary",
            showInNav: true,
            requiredAuth: false,
            // slug: "nav_es",
            name: t("nav.es"),
            icon: "scale",
            activeMenu: "erp",
            // permissions: ["nav_es"],
        },
        children: [
            {
                path: "",
                name: "es-index",
                component: () => import("../views/salary/Index.vue"),
                children: [
                    {
                        path: "/es/stat",
                        name: "salary-manage-stat",
                        component: () => import("../views/salary/SalaryStat.vue"),
                        meta: { title: "薪资统计 - Salary Stat" },
                    },
                    {
                        path: "/es/detail",
                        name: "salary-manage-detail",
                        component: () => import("../views/salary/SalaryDetail.vue"),
                        meta: { title: "薪资明细 - Salary Detail" },
                    },
                    {
                        path: "/es/setting",
                        name: "salary-manage-setting",
                        component: () => import("../views/salary/SalarySetting.vue"),
                        meta: { title: "薪资配置 - Salary Setting" },
                    },
                    {
                        path: "/es/workers",
                        name: "salary-manage-account",
                        component: () => import("../views/salary/WorkersManage.vue"),
                        meta: { title: "工人管理 - Workers Manage" },
                    },
                ],
            },
        ],
    },
];
