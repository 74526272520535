import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/sensor",
        name: "sensor",
        redirect: "/sensor/basic",
        component: () => import("@/views/sensor/Index.vue"),
        meta: {
            title: "智控平台 - Sensor",
            showInNav: true,
            sort: 10,
            name: t("nav.sensor"),
            icon: "basic",
        },
        children: [
            {
                path: "basic",
                name: "sensor-basic",
                component: () => import("@/views/sensor/basic/Index.vue"),
                meta: {
                    title: "传感器 - Basic",
                    showInNav: true,
                    name: t("nav.sensor_basic"),
                    icon: "sensor",
                    activeMenu: "sensor",
                },
            },
            {
                path: "control",
                name: "sensor-control",
                component: () => import("@/views/sensor/control/Index.vue"),
                meta: {
                    title: "控制器 - Controller",
                    showInNav: true,
                    name: t("nav.sensor_control"),
                    icon: "control",
                    onlyDashboard: true,
                    activeMenu: "sensor",
                },
            },
            {
                path: "irrigate",
                name: "sensor-irrigate",
                component: () => import("@/views/sensor/irrigate/Index.vue"),
                meta: {
                    title: "灌溉助手 - Irrigate",
                    showInNav: true,
                    name: t("nav.sensor_irrigate"),
                    icon: "irrigate",
                    activeMenu: "sensor",
                },
            },
            {
                path: "prop",
                name: "sensor-prop",
                component: () => import("@/views/sensor/prop/Index.vue"),
                meta: {
                    title: "实时读数 - Prop",
                    showInNav: true,
                    name: t("nav.sensor_prop"),
                    icon: "follow",
                    activeMenu: "sensor",
                },
            },
            {
                path: "task",
                name: "sensor-task",
                component: () => import("@/views/sensor/task/Index.vue"),
                meta: {
                    title: "定时任务 - Task",
                    showInNav: true,
                    name: t("nav.sensor_task"),
                    icon: "task",
                    activeMenu: "sensor",
                },
            },
        ],
    },
];
