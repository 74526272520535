export default {
    // 智能果园
    aigrow: {
        title: "智能果园",
        gardens: {
            title: "园区概览",
        },
        overview: {
            add: "新增果园",
            edit: "编辑果园",
            group_manage: "分组管理",
            name: "名称",
            placeholder_name: "请输入名称",
            group: "分组",
            select_group: "请选择分组",
            sort: "排序",
            placeholder_sort: "请输入排序",
            remark: "备注",
            placeholder_remark: "请输入备注",
            no_group: "未分组",
            only_edit: "编辑",
            only_delete: "删除",
            untitled: "未命名",
            update_time: "更新时间",
            no_match: "当前分组没有分配果园",
            setting: "设置",
            tips: "提示",
            confirm_delete: "是否确认删除该果园（该操作不可恢复）?",
            enter_group_name: "请输入分组名称",
            group_name_error: "分组名称不能为空",
            duplicate_group_names: "重复的分组名",
            del_message: "您确定要删除吗? 此操作将无法撤消。",
            sort_btn_start: "开始排序",
            sort_btn_stop: "结束排序",
            empty_garden: "暂不存在果园",
            stat: "共{albums}个相册，{photos}张照片",

            // overview
            completed: "已完成",
            queue: "队列中",
            processing: "处理中",
            today_process: "今日处理",
            yest_process: "昨日处理",
            month_process: "本月处理",
            zhang: "张",
            buy: "已购买模型:",
            num: "已使用额度:",
        },
        upload: {
            drop_file: "拖拽文件到此处，或",
            click_upload: "点击上传",
            file_tip: "单次最多上传{limit}张图片，每张图片大小不超过{max}MB",

            start_upload: "上传图片",
            select_album: "选择相册",
            select_analyzer: "分析器版本",
            select_model: "模型",
            reset: "重置",
            over_limit: "超过最大上传数量限制",
            album_required: "请选择相册",
            analyzer_required: "请选择分析器版本",
            start_analyze: "开始分析",
            cache_tip: "您有未完成的任务，继续进行吗？",
            continue: "继续",
            upload_finish_tips: "上传已就绪，点击下方开始分析，也可以继续追加图片完毕后再进行提交。",
            over_size: "超过最大上传大小限制",
            over_size_msg: "超出大小限制的文件已自动移除。",

            model_list: {
                raspberry: "树莓",
                blueberry: "蓝莓",
            },
        },
        ripen: {
            title: "果熟识别",
            process_finish: "您有一张图片已经处理就绪",
            to_view: "点击查看结果",

            notification_title: "【AI Grow】您有一张图片处理已就绪",
            album: "相册",
            photo: "图片",
        },
        albums: {
            garden_list: "果园列表",
            garden_album: "果园相册",
            no_garden_albums: "该果园暂无相册，请先在相册中绑定果园。",
            title: "全部相册",
            add: "新增相册",
            edit: "编辑相册",
            setting: "设置",
            tips: "提示",
            update_time: "更新时间",
            create_time: "创建时间",
            desc: "倒序",
            asc: "正序 ",
            upload: "立即上传",
            default_album: "默认图标",
            sticky: "置顶",
            unsticky: "取消",
            /**
             * 新增编辑表单
             */
            confirm_delete: "是否确认删除该相册（该操作不可恢复）?",
            only_edit: "编辑",
            only_delete: "删除",
            untitled: "未命名",
            description: "描述",
            no_description: "暂无描述",
            placeholder_description: "请输入描述",
            name: "名称",
            placeholder_name: "请输入名称",
            icon: "图标",
            select_icon: "请选择图标",
            /**
             * 单页
             */
            frozen: "锁定",
            thaw: "解锁",
            frozen_tips: "锁定后将不能对该相册追加图片",
            is_frozen: "已被锁定",
            confirm: "确定",
            cancel: "取消",
            save: "保存",
            add_param: "自定义参数",
            edit_param: "编辑参数",
            key: "键",
            val: "值",
            remark: "备注",
            placeholder_key: "请输入键",
            placeholder_val: "请输入值",
            placeholder_remark: "请输入备注",
            placeholder_remark_update: "请输入键为{key}的备注",
            no_data: "暂无数据",
            param_config_tip: "请先配置相册参数",
            param_select_tip: "请先选择一个参数",
            album_param: "相册参数",
            associated_garden: "关联果园",
            untie: "解绑",
            untie_tips: "确定解绑吗？",
            associated_dashboard: "关联园区",
            bind: "绑定",
            select_dashboard: "请选择园区",
            select_garden: "请选择果园",

            album_chart: "相册图表",
            batch_list: "批次列表",
            user_log: "用户日志",
            line: "样本分析",
            bar: "产量预测",

            records: "条记录",
            prev: "上一页",
            next: "下一页",

            operate: "操作",
            user: "用户",
            method: "方式",
            ip: "IP",
            ua: "User Agent",
            time: "时间",

            item: {
                creator: "创建人",
                default_desc: "",
                bind_garden: "绑定果园",
                bind_node: "绑定节点",
                detail: "查看详情",
                default_name: "匿名",
            },

            chart: {
                actual: "实际",
                predicted: "预测",
                analyzing: "分析中",
                actual_chart: "实际图",
                predicted_chart: "预测图",
                all: "全部",
                start: "开始时间",
                end: "结束时间",
                to: "至",
                last_7_days: "最近7日",
                last_3_days: "最近3日",
                yesterday: "昨日",
                today: "今日",
                last_month: "最近1个月",
                last_3_months: "最近3个月",
                last_6_months: "最近6个月",
                last_24_hours: "24小时",
                total_output: "总产量",
                harvesting_progress: "采收进度",
            },

            actions: {
                upload_photo: "上传图片",
                config_album: "配置相册",
                create_album: "创建相册",
                update_album: "更新相册",
                delete_album: "删除相册",
                freeze_album: "锁定相册",
                sticky_album: "置顶相册",
                bind_garden: "绑定果园",
                unbind_garden: "解绑果园",
                bind_node: "绑定节点",
                unbind_node: "解绑节点",
                edit_photo: "编辑图片",
                delete_photo: "删除图片",
                config_stack: "配置批次",
                update_stack: "更新批次",
                delete_stack: "删除批次",
            },
        },
        photo: {
            all: "全部相册",
            un_name: "未命名",
            remark: "备注",
            uploaded_at: "上传时间",
            remark_placeholder: "请输入备注",
            // 是否参与分析
            is_analyze: "参与计算",
            participate_tip: "当不参与计算时，该图片数据不会被计入到总数据分析中",
            del_confirm: "您确定要删除吗? 此操作将无法撤消。",
            redo: "重新分析",
            // 分析前后
            before: "分析前",
            after: "分析后",
            source: "原图",
            processed: "处理后",
            all_photo: "对比",
            processing: "队列处理中...",
            total_photos: "本批次共{count}张图片",

            bar: "柱状图",
            pie: "饼图",
            pie_name: "分析结果",

            // 表格
            type: "类型",
            count: "数量",
            amend: "修正",

            process_status: {
                unclaimed: "等待中",
                published: "已派发",
                claimed: "队列中", //已领取
                assigned: "已分配",
                queued: "队列中",
                processing_started: "开始处理",
                processing: "处理中",
                in_review: "审核中",
                awaiting_publishing: "等待发布",
                reported: "已报告",
                completed: "渲染中，请勿关闭窗口...",
            },

            // 果实状态
            ripen_status: {
                matured: "成熟",
                intermediate: "半成熟",
                flower: "开花期",
                flowerbud: "花芽期",
                harvested: "已收获",
            },
        },
        params: {
            area: "面积",
            row_spacing: "行距",
            plant_spacing: "株距",
            marker_spacing: "标识物间距",
            number_of_rows: "行数",
            row_length: "行长",
            k_intermediate: "未采收系数",
            k_flowers: "花量系数",
            pick: "摘果",
        },
        stack: {
            move: "移动",
            append: "追加",
            move_stack: "批次移动",
            select_album: "请选择要移入的相册",
            enter_keyword: "请输入相册名称",
            move_message: "确定要将该批次移动到新的相册吗？",
            pie: "饼图",
            photo_list: "图片列表",
            intervention: "干预信息",
            info: "批次信息",
            batch: "批次",
            upload: "上传",
            single: "数据分析",
            limit: "请输入1-50位字符",
            photo: "图片",
            all: "全部",
            total: "合计",
            average: "均值",
            filming_time: "拍摄时间",
            created_at: "上传时间",
            select_filming_time: "请设置拍摄时间",
            select_filming_date: "请选择拍摄日期",
            save: "保存",
            remark: "备注",
            creator: "上传人",
            operate: "操作",
        },
    },
};
