import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/insights",
        name: "insights",
        redirect: "/insights/nodes",
        component: () => import("@/views/insights/Index.vue"),
        meta: { title: "智控平台 - Insights", showInNav: true, sort: 50, name: t("nav.insights"), icon: "conf" },
        children: [
            {
                path: "conf",
                name: "insights-node",
                component: () => import("@/views/insights/Conf.vue"),
                meta: {
                    title: "园区配置 - Node Management",
                    showInNav: true,
                    name: t("nav.insights_node"),
                    icon: "node",
                    activeMenu: "insights",
                },
            },
            {
                path: "nodes",
                name: "insights-controller",
                component: () => import("@/views/insights/Nodes.vue"),
                meta: {
                    title: "节点配置 - Node Setting",
                    showInNav: true,
                    name: t("nav.insights_controller"),
                    icon: "controller",
                    activeMenu: "insights",
                },
            },
            {
                path: "template",
                name: "insights-template",
                component: () => import("@/views/insights/Template.vue"),
                meta: {
                    title: "模板配置 - Template Management",
                    showInNav: true,
                    name: t("nav.insights_template"),
                    icon: "template",
                    activeMenu: "insights",
                },
            },
        ],
    },
];
