module.exports = {
    // 应用标识
    APP_NAME: "yielda",
    APP_TITLE: "Yielda",

    // 侧边栏
    ACTIVE_MODULES: {
        exclude: ["es", "erp", "enterprise", "doc", "support"],
    },

    // 默认语言和时区
    DEFAULT_LOCALE: "en-us",
    DEFAULT_TIMEZONE: "Europe/Brussels",

    // 登录界面
    OFFICIAL_SITE: "yielda.farm",
    ABOUT_PAGE: "https://yielda.farm/about",
};
