// 1.Dependency
import { createRouter, createWebHistory } from "vue-router";
import { filter, flatten } from "lodash";
import { hasPermission } from "@/directives/permission";
import { useUserData } from "@/store/userData";
import { getPermissionList } from "@/service/permission";
import User from "@deepberry/titan-web-components/src/utils/user";
import { getCurrentOrganization, getProfile, switchOrg } from "@/service/user";

import { i18n } from "@/locale";

const {
    global: { t },
} = i18n;

// 2.Routes
const files = require.context("./", true, /\.ts$/);
const routesModules = files.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = files(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

export const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("@/views/Index.vue"),
    },
    ...flatten(
        filter(routesModules, (module, moduleName) => {
            return module;
        })
    ),
    { path: "/:pathMatch(.*)*", name: "NotFound", component: () => import("@/views/_/NotFound.vue") },
    { path: "/forbidden", name: "NotAuthenticated", component: () => import("@/views/_/NotAuthenticated.vue") },
];

// 3.Build An Instance
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: (to, from, savePosition) => {
        if (savePosition) {
            return savePosition;
        } else {
            return {
                top: 0,
            };
        }
    },
});

// 4.Set document title
router.beforeEach(async (to, from, next) => {
    if (to.name !== "account-login") {
        if (!User.isAuthenticated()) {
            User.destroy();
            User.toLogin();
            return;
        }
        // 如果token距离过期小于3天，刷新token
        if (User.needRefreshToken(3)) {
            console.log("刷新token");
            await User.refreshToken();
        }
        // 获取permission
        const permission = sessionStorage.getItem("titan_permissions");

        if (!permission) {
            await getPermissionList().then((res) => {
                if (res.data.data?.length) {
                    sessionStorage.setItem("titan_permissions", JSON.stringify(res.data.data));
                    useUserData().permissions = res.data.data;
                }
            });
        } else {
            try {
                useUserData().permissions = JSON.parse(permission);
            } catch (e) {
                console.error("permission解析失败", e);
            }
        }

        // 获取用户组织
        useUserData().organizations = await getCurrentOrganization().then((res) => res.data.data);

        // 获取用户信息
        useUserData().profile = await getProfile().then((res) => res.data.data);

        await useUserData().sync();
        // 如果用户没有组织，自动切换到第一个组织，并刷新token
        if (!useUserData().profile?.organization) {
            if (useUserData().organizations?.length) {
                switchOrg(useUserData().organizations[0]?.organization_id).then(async () => {
                    await User.refreshToken();
                });
            } else {
                // 如果是因为没有组织和disabled=1跳转到user-profile或者NotAuthenticated，不需要判断token
                if (to.name === "user-profile") {
                    next();
                    return;
                }
                // 没有组织，跳转到个人中心
                next({ name: "user-profile" });
                return;
            }
        }
        // 如果用户组织信息和token内的组织信息不一致，刷新token，则尝试切换组织信息内的组织
        // if (useUserData().profile?.organization?.id !== useUserData().profile.token?.oid) {
        //     switchOrg(useUserData().profile?.organization?.id).then(async () => {
        //         await User.refreshToken();
        //     });
        // }

        User.setLocale(useUserData().profile?.preference?.locale?.toLowerCase() || "zh-cn");
        User.setTimezone(useUserData().profile?.preference?.timezone || "Asia/Shanghai");

        if (useUserData().profile.disabled) {
            // 如果是因为没有组织和disabled=1跳转到user-profile或者NotAuthenticated，不需要判断token
            if (to.name === "NotAuthenticated") {
                next();
                return;
            }
            next({ name: "NotAuthenticated" });
            return;
        }
    }

    if (to.name) {
        document.title = ((to.meta?.name || t(`route_name.${String(to.name)}`)) + " - DeepBerry") as string;
    }
    // permission
    if (to.meta.permissions) {
        if (!hasPermission(to.meta.permissions as string[])) {
            next({ name: "NotAuthenticated" });
            return;
        }
    }
    next();
    return;
});

// 5.Compatible
const query = new URLSearchParams(location.search);
const token = query.get("__token");
const force = query.get("__force");
if (token) {
    sessionStorage.setItem("TOKEN_TITAN", token);
    if (force) localStorage.setItem("TOKEN_TITAN", token);
}

export default router;
