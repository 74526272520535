export default [
    {
        path: "/help",
        name: "help",
        redirect: "/help/doc",
        component: () => import("@/views/help/Doc.vue"),
        meta: { title: "文档中心 - help" },
        children: [
            {
                path: "doc",
                name: "help-list",
                component: () => import("@/views/help/components/DocList.vue"),
                meta: { title: "帮助文档列表 - Help List" },
            },
            {
                path: "doc/:id(\\d+)",
                name: "help-detail",
                component: () => import("@/views/help/components/DocDetail.vue"),
                meta: { title: "帮助文档详情 - Help Detail" },
            },
        ],
    },
];
