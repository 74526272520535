import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/overview",
        name: "overview",
        redirect: "/overview/dashboard",
        component: () => import("@/views/overview/Index.vue"),
        meta: { title: "总览 - Overview", showInNav: true, sort: 0, name: t("nav.overview"), icon: "overview" },
        children: [
            // {
            //     path: "park",
            //     name: "overview-park",
            //     component: () => import("@/views/overview/Park.vue"),
            //     meta: {
            //         showInNav: true,
            //         slug: "nav_management",
            //         title: "园区管理 - Management",
            //         name: t("nav.overview_management"),
            //         icon: "map",
            //         hiddenInProduction: true,
            //     },
            //     children: [
            //         {
            //             path: ":id(\\d+)",
            //             name: "overview-park-detail",
            //             component: () => import("@/views/overview/ParkDetail.vue"),
            //             meta: {
            //                 title: "园区详情 - Detail",
            //                 name: t("nav.overview_park_detail"),
            //             },
            //         },
            //     ],
            // },
            // {
            //     path: "map",
            //     name: "overview-map",
            //     component: () => import("@/views/overview/Map.vue"),
            //     meta: {
            //         title: "园区规划 - Map",
            //         showInNav: true,
            //         slug: "nav_parkmap",
            //         name: t("nav.overview_map"),
            //         icon: "map",
            //     },
            // },
            {
                path: "dataview",
                name: "overview-dataview",
                component: () => import("@/views/overview/Dataview.vue"),
                meta: { title: "数据透视 - Dataview", activeMenu: "overview" },
            },
            {
                path: "dashboard",
                name: "overview-dashboard",
                component: () => import("@/views/overview/monitor/Index.vue"),
                redirect: {
                    name: "overview-dashboard-list",
                },
                meta: {
                    title: "监控大盘 - Dashboard",
                    requiredAuth: false,
                    showInNav: true,
                    slug: "nav_monitor",
                    name: t("nav.overview_dashboard"),
                    icon: "chart",
                    activeMenu: "overview",
                    // permissions: ["nav_monitor"],
                },
                children: [
                    {
                        path: "",
                        name: "overview-dashboard-list",
                        component: () => import("@/views/overview/monitor/List.vue"),
                        meta: { title: "监控大盘 - Monitor" },
                    },
                    {
                        path: "detail/:uuid",
                        name: "overview-dashboard-detail",
                        component: () => import("@/views/overview/monitor/Detail.vue"),
                        meta: { title: "监控详情 - Monitor" },
                    },
                ],
            },
            {
                path: "device",
                name: "overview-device",
                component: () => import("@/views/overview/Devices.vue"),
                meta: {
                    title: "设备中心 - Device",
                    requiredAuth: false,
                    showInNav: true,
                    slug: "nav_overview_device",
                    name: t("nav.overview_device"),
                    icon: "device",
                    activeMenu: "overview",
                },
            },
        ],
    },
];
