export default {
    parkmap: {
        title: "Park Map",
        height: "Height",
        width: "Width",
        ratio: "Ratio",
        background: "Background",
        name: "Name",
        area: "Area",

        rect: "Rect",
        select_rect: "Select Rect",
        line: "Line",
        select_line: "Select Line",

        rotate: "Rotate",
        length: "Length",
        gutter: "Gutter",
        col: "Col",
        x: "X",
        y: "Y",
        triangle: "Triangle",
        select_triangle: "Select Triangle",
        padding_left: "Left",
        padding_top: "Top",
        padding_right: "Right",
        padding_bottom: "Bottom",

        param_setting: "Param Setting",
        select_element: "Please select element",
        node_select: "Node",
        dashboard_select: "Dashboard",

        edit: "Edit",
    },
};
