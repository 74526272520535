import { $titan2 } from "@/utils/api";

// 获取用户权限列表
export function getPermissionList() {
    return $titan2().get(`/user/permission`);
}

// 获取所有权限
export function getAllPermissions() {
    return $titan2().get(`/account/permission`);
}

// 获取指定用户权限列表
export function getUserPermissions(id) {
    return $titan2().get(`/account/permission/user/${id}`);
}

// 给用户授权
export function setUserPermissions(id, data) {
    return $titan2().post(`/account/permission/user/${id}`, data);
}

// 获取指定角色权限列表
export function getRolePermissions(id) {
    return $titan2().get(`/account/permission/role/${id}`);
}

// 给角色授权
export function setRolePermissions(id, data) {
    return $titan2().post(`/account/permission/role/${id}`, data);
}

// 给用户设置角色
export function setUserRoles(id, data) {
    return $titan2().post(`/account/permission/user/${id}/role`, data);
}
