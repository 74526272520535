export default {
    // 帮助中心
    help: {
        doc: {
            title: "Help document",
            back_list: "Back",
            back_home: "Back",
            search: "Search",
            doc_list_title: "Deepberry Help Center",
            doc_list_welcome: "Welcome to DeepBerry Help Center!",
            contact_us: "Contact us",
            update_time: "Update time",
            scan: "Use mobile phone to scan code to access",
            phone_read: "Phone-reading",
            no_group: "No Group",
            no_data: "No Document",
        },
    },
};
