export default {
    // 实时监控
    sensor: {
        indicator: {
            title: "关键指标",
            chart: "指标图表",
            empty: "当前节点没有数据",

            par: "光合有效辐射",
            par_integral: "光合积分量",
            co2: "二氧化碳浓度",
            air_temperature: "空气温度",
            dew_point: "露点温度",
            frost_point: "霜点温度",
            air_humidity: "空气湿度",
            absolute_humidity: "绝对湿度",
            VPD: "VPD",
            vpd: "VPD",
            soil_ec: "土壤EC",
            soil_temperature: "土壤温度",
            soil_humidity: "土壤湿度",
            soil_water: "土壤含水量",
            pore_ec: "土壤poreEC",
            discharge_ratio: "排液比",
            drainage_ec: "排液EC",
            radiation: "太阳总辐射",
        },
        indicator_types: {
            light: "光",
            air: "空气",
            heat: "热",
            water: "水",
            soil: "土壤",
            root: "根系",
            plant: "植物",
        },

        outdoor: {
            title: "室外环境",
            no_geo: "尚未设置地理信息",
            click_to_configure: "点击配置",
            next_24_hour: "未来24小时逐小时预报",
            OAT: "室外温度",
            Td: "露点温度",
            RH: "相对湿度",
            HPD: "小时降水",
            Pa: "大气压强",
            WS: "风速",
            wind_direction: "风向",
            cloud: "云量",

            no_data: "暂无天气数据",
        },

        device: {
            title: "设备绑定",
            advanced_configuration: "高级配置",
            empty_device: "暂未绑定任何设备",
        },

        controls: {
            global: "全局控制",
            global_warning: "请谨慎操作",
            open: "开启",
            close: "关闭",
            global_tip: "您确定要{config}所有开关吗？",
            untitled: "未命名",
            custom: "自定义",
            selected_all: "组操作",
            batch_control: "批量控制",
            empty: "暂未添加任何开关",
            current_node: "节点",
            group_manage: "分组管理",
            sort_btn_start: "开始排序",
            sort_btn_stop: "结束排序",
            no_group: "未分组",
            group_edit: "分组编辑",
            job_edit: "任务编辑",
            order: "排序",
            group: "分组",
        },
        tasks: {
            title: "当前任务",
            name_edit: "编辑任务名称",
            more: "等{count}个",
            status: "状态",
            on: "启用中",
            off: "停用中",
            trigger: "执行",
            coding: "编辑",
            template: "模版",
            desc: "任务描述",
            name_placeholder: "请输入任务名称",
            source_data: "数据",
            cron_tip: "可通过微任务同时设置多个执行规则",
            empty_text: "暂未添加任务",
            cron_title: "定时规则设置",
            untitled: "未分组",
            success: "任务下发成功",
            unknown_error: "未知异常",
            all: "全部",

            save_as_temp: "保存为模版",
            empty_template: "暂无模版",
            templates: {
                title: "预设任务",
                public: "公共模板",
                user: "私有模板",
                no_group: "未分组",
                insert: "导入",
                description: "任务描述",
                cron_exps: "定时规则",
                no_template: "暂无模板",
                to_create: "去创建模板",
                join_group: "加入分组",
                duplicate_names: "重复的定时任务名称",
                enter_name: "请输入定时任务名称",
            },
            logs: {
                title: "执行日志",
                log_status: "状态",
                log_time: "时间",
                log_detail: "详情",
                task_name: "任务名",
                empty_log: "无近期执行记录",
                empty: "暂无任何执行日志",
                execution_duration: "执行时长",
                remark: "备注",
                edit_remark: "编辑备注",

                start: "开始日期",
                end: "结束日期",

                SUCCESS: "成功",
                FAILED: "失败",
                unknown: "未知",
                undefined: "未知",
            },
        },

        irrigates: {
            // tabs
            title: "灌溉助手",
            summary: "灌溉态势",
            crop_balance: "作物均衡",
            water_change: "含水量变化",
            sidebar_title: "实时数据",
            sidebar_null: "暂无数据",

            // summary
            irrigate: "灌溉平衡",
            drain: "排液详情",
            discharge_ratio: "灌溉排液比",
            irrigation_efficiency: "灌溉光照比",

            // 累计值
            total_irrigate: "累计灌溉",
            // 瞬时值
            instant_irrigate: "瞬时灌溉",

            // crop balance
            energy: "作物能量平衡",
            biomass_accumulation: "生物积质累量",
        },

        preset: {
            title: "指标推荐预设",
            preset_plan: "预设方案",
        },

        // 气孔相机
        stomata: {
            dli: "日光积分",
            g_water: "绝对湿度",
            vbat: "电池百分比",
            par: "PAR",
            temp: "气温",
            humid: "相对湿度",
            t_dew: "露点温度",
            t_leaf: "干叶温度",
            vpd: "VPD",
            history: "历史数据",

            prev: "上一张",
            next: "下一张",
            auto: "自动播放",
            stop: "停止播放",
            distance: "播放间隔",
            distance_placeholder: "请输入间隔时间",
            next_soon: "即将播放下一张..",
            current_time: "当前时间",
            prev_time: "上一张时间",
            next_time: "下一张时间",
            empty: "当前节点没有照片数据",
            camera_snapshot: "相机快照",
            camera_config: "相机参数",
            dry_leaf_config: "干叶设置",
            edit_dry_leaf_position: "编辑干叶位置",
            point_name_required: "请输入点名称",
            point_name: "点名称",
            point_x_required: "请输入X坐标",
            point_x: "X",
            point_y_required: "请输入Y坐标",
            point_y: "Y",
            camera_placeholder: "请输入相机配置",

            // 原图
            original: "原图",
            // 温度
            temperature: "温度",
            // 气孔
            stomata: "气孔",
            temperature_error: "异常",
        },

        // 关注指标
        follows: {
            title: "实时读数",
            all: "全部指标",
            empty: "暂无任何指标",
            reading: "关注读数",
            add: "添加实时读数",

            public: "公开",
            set_public: "设为公开",
            set_private: "取消公开",
            follow: "设为关注",
            unfollow: "取消关注",
        },

        // 计算属性
        computed: {
            title: "计算指标",
            add: "添加计算指标",
            edit: "编辑计算指标",
            name: "属性名",
            display_name: "显示名",
            name_required: "请输入计算指标属性名称",
            display_name_required: "请输入计算指标显示名称",
            formula: "公式",
            formula_required: "请输入计算公式",
            formula_placeholder: "请用$1,$2,...$n代替变量",
            formula_preview: "公式预览",
            variable_validate: "变量格式为 $n ",
            variable_unknown: "存在未知变量",
            formula_error: "公式格式错误",
            prop_key: "请匹配属性",
            data_set: "数据设置",
            unit: "单位",
            data_persistence: "数据持久化",
            prop_match: "属性匹配",
            data_type: "数据类型",
            normal: "普通",
            sum: "累计",
            window: "时间窗口(s)",
            prop: "属性",
            prop_select: "请选择来源属性",
            prop_type: "属性类型",
            prop_type_select: "请选择属性类型",
            hour: "累计时间(h)",
            depend_tip: "依赖属性已不存在，请重新匹配",
            del_message: "您确定要删除吗? 此操作将无法撤消。",
        },
    },
};
