import { App } from "vue";

const hasPermission = (value: string[]): boolean => {
    const permissions = sessionStorage.getItem("titan_permissions");
    if (permissions) {
        try {
            const permissionsArr = JSON.parse(permissions);
            return permissionsArr.some((item: string) => value.includes(item));
        } catch (error) {
            return false;
        }
    }
    return false;
};

const permissionInstaller = (app: App) => {
    app.directive("permission", {
        mounted(el, binding) {
            const { value } = binding;

            // console.log("permission", value);

            // 数组或者字符串
            if (value) {
                // 没有权限则disabled元素，添加class
                if (Array.isArray(value)) {
                    if (!hasPermission(value)) {
                        el.parentElement?.classList.add("c-permission-parent");
                        el.classList.add("c-permission-disabled");
                        // 禁止点击
                        el.setAttribute("disabled", "disabled");
                    }
                } else if (typeof value === "string") {
                    if (!hasPermission([value])) {
                        el.parentElement?.classList.add("c-permission-parent");
                        el.classList.add("c-permission-disabled");
                        // 禁止点击
                        el.setAttribute("disabled", "disabled");
                    }
                }
            }
        },
    });
};

export { permissionInstaller, hasPermission };
