export default {
    // 监控大盘
    monitor: {
        title: "监控大盘",
        add_dashboard: "添加大盘",
        show_other: "显示其他人",
        edit_dashboard: "编辑大盘",
        dashboard_name: "大盘名称",
        dashboard_placeholder: "请输入大盘名称",
        dashboard_name_required: "请输入大盘名称",
        delete_dashboard_confirm: "确定删除该大盘吗？",
        delete_dashboard: "删除大盘",
        delete_chart: "删除图表",
        delete_chart_confirm: "确定删除此图表吗？",
        no_dashboard: "没有任何监控大盘",

        copy_chart: "复制图表",
        copy_chart_new_name: "新复制图表的名称",
        example_op_warn: "示例程序无法操作",

        // 新版
        new: "新版",
        old: "旧版",

        update_time: "更新时间",
        create_time: "创建时间",
        minute: "分钟",
        second: "秒",
        cross_node: "跨节点",
        cross_album: "跨相册",

        actions: {
            edit: "编辑",
            delete: "删除",
            copy_success: "复制成功",
            refresh: "刷新",
            copy: "复制",
            export: "导出",
            fullscreen: "大图",
            more: "更多",
            public: "设为公共大盘",
            public_tooltip: "组织下所有成员可见",
            private: "设为私有大盘",
            private_tooltip: "仅自己可见",
            setting: "设置",
        },

        // 详情
        add_view: "添加图表",
        // 编辑布局
        edit_layout: "编辑布局",
        save_layout: "保存布局",

        times: {
            "1h": "1小时",
            "6h": "6小时",
            "12h": "12小时",
            "1d": "1天",
            "3d": "3天",
            "7d": "7天",
            custom: "自定义",
        },

        // 自动刷新
        auto_refresh: "自动刷新",
        by_line: "每行展示{count}",
        no_data: "暂无数据",

        // drawer
        edit_view: "编辑图表",
        chart_types: {
            line: "折线图",
            bar: "柱状图",
            pie: "饼图",
            area: "面积图",
            dashboard: "仪表盘",
            radar: "雷达图",
            preset: "其它",
        },
        sub_types: {
            line_switch: "开关折线图",
            sub_type_placeholder: "请选择子类型",
            open: "开",
            close: "关",
            line: "折线图",
            bar: "柱状图",
        },
        data_types: {
            sensor: "传感器",
            aigrow: "果熟识别",
        },
        tabs: {
            data: "数据选项",
            chart: "图表选项",
        },
        form: {
            name: "名称",
            name_placeholder: "请输入名称",
            data_source: "数据源",
            prop: "属性",
            prop_placeholder: "搜索属性",
            data_type: "数据类型",
            type_placeholder: "请选择数据类型",
        },
        table: {
            property: "属性",
            value: "值",
            setting: "设置",
            filter: "请先确定数据源",
        },
        data_source: {
            organization: "组织",
            dashboard: "园区",
            node: "节点",
            album: "相册",
        },
        prop_setting: {
            sub_type: "子类型",
            window: "时间窗口",
            color: "颜色",
            line_type: "线条",
            solid_line: "实线",
            dashed_line: "虚线",
            with_label: "带标签",
            bar_ruler: "颜色规则",
            true: "是",
            false: "否",
        },
        config_property: "配置属性",
        one_item: "第{number}项",
        delete_this: "是否确定删除此项？",
        number: {
            1: "一",
            2: "二",
            3: "三",
            4: "四",
            5: "五",
            6: "六",
            7: "七",
            8: "八",
            9: "九",
            10: "十",
        },
        chart_option: {
            chart_type: "类型",
            smooth: "线条平滑",
            background_color: "背景色",
            direction: "方向",
            min: "最小值",
            max: "最大值",
            extreme_value_annotation: "极值标注",
            avg_line: "平均线",
            grade_option: "等级选项",
            theme_color: "主题颜色",
            normalization: "归一展示",

            default: "默认",
            bar_chart: "条形图",
            line_bar_chart: "折线柱图混合",

            pie_chart: "饼图",
            ring_chart: "环形图",
            speed_chart: "速度",
            progress_chart: "进度",
            grade_chart: "等级",
            temperature_chart: "温度",
            scoring_chart: "分数",

            name: "名称",
            config: "配置",
            distinguish_day_and_night: "区分昼夜",
            filter_break_point: "过滤断点",
            compensation_point: "补偿点",
            open_filter_break_point: "开启后不再支持区分昼夜",
            open_distinguish_day_and_night: "开启后不再支持过滤断点",
        },
    },
    dashboard: {
        add: "创建园区",
        edit: "编辑园区",
        device: "设备",
        coordinate: "坐标",
        view: "查看园区",
        copy: "复制坐标",
        copy_success: "复制成功",
        province_placeholder: "请选择省份",
        all_dashboards: "全部园区",

        // 统计
        statistic: {
            home: "园区",
            region: "片区",
            node: "节点",
            device: "设备",
        },

        // 表单
        form: {
            name: "名称",
            name_placeholder: "请输入名称",
            remark: "备注",
            remark_placeholder: "请输入备注",
            del_message: "您确定要删除{config}园区吗? 此操作将无法撤消。",
        },
        // 园区详情
        detail: {
            set: "设置园区",
            manager: "园区管理员",
        },
    },

    devices: {
        add: "添加设备",
        edit: "编辑设备",
        no_data: "没有设备",
        input_device_remark_placeholder: "请输入设备备注",

        node_type: "节点类型",
        device_id: "设备ID",
        hardware_version: "硬件版本",
        software_version: "固件版本",
    },
};
