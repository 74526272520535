export default {
    // 园区管理
    insights: {
        nodes: {
            current_node: "Current Node",
            selection_node: "Select Node",
            title: "Node",
            manage: "Nodes",
            type: "Type",

            // 筛选
            sort_btn_start: "Start Order",
            sort_btn_stop: "Stop Order",

            // 表单
            add: "Add Node",
            edit: "Edit Node",
            dashboard: "Dashboard",
            name: "Name",
            remark: "Remark",
            dashboard_placeholder: "Please select dashboard",
            name_placeholder: "Please input name",
            remark_placeholder: "Please input remark",
            search_placeholder: "Please input keywords",

            // 设置
            delete: "Delete",
            confirm_delete: "Are you sure to delete this node?",
            confirm_delete_prefix: "Are you sure to delete ",
            confirm_delete_suffix: " ?",
            upload_template: "Upload Template",
            download_template: "Download Template",
            empty_group: "No group",
            to_create: "Create A Group",

            // 列表
            untitled: "Untitled",
            empty_list: "No data",

            // 操作
            prop: "Properties",
            chart: "Charts",
            params: "Params",
            cron: "Tasks",
            device: "Devices",
            node_template: "Template",
            settings: "Settings",
            geo: "Geography",
            irrigate: "Irrigation",
        },
        dashboard: {
            current_dashboard: "Current",
            selection_dashboard: "Select Dashboard",
            drawer_title: "Dashboard",

            dashboard: "Dashboard",
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            confirm_delete: "Are you sure to delete this dashboard?",
            add_dashboard: "Add Dashboard",

            dashboards: "Dashboards",
            nodes: "Nodes",

            search_placeholder: "Search Dashboard",

            // form
            name: "Name",
            name_placeholder: "Please input name",
            remark: "Remark",
            remark_placeholder: "Please input remark",

            auth: "Authorize",
            grant: "Grant",
            select_user: "Select",
            unknown: "Unknown",
            remove: "Remove",
            confirm_remove: "Are you sure to remove this user?",
            invalid: "<Untethered phone>",
        },
        panel: {
            add: "Add",
            edit: "Edit",
            start: "Start",
            stop: "Stop",
            del: "Delete",
            tips: "Tips",
            copy: "Copy",
            enabled: "Enable",
            disabled: "Disable",
            action: "Operation",
            note: "Remark",
            ok: "OK",
            cancel: "Cancel",
            all: "All",
            inverse: "Inverse",
            prop: {
                add: "Add Attribute",
                edit: "Edit Attribute",
                attribute_type: "Attribute Type",
                attribute_type_placeholder: "Please select attribute type",
                attribute_name_exist: "Attribute name already exists",
                attribute_name: "Attribute Name",
                attribute_name_placeholder: "Please enter the attribute name",
                attribute_display_name: "Display Name",
                attribute_display_name_placeholder: "Please enter the display name of the attribute",
                attribute_value: "Attribute Value",
                attribute_value_placeholder: "Please enter the attribute value",
                data_type: "Date Type",
                view_model: "View Mode",
                view_model_placeholder: "Please select view mode",
                view_meta: "View Meta",
                view_meta_min: "Min",
                view_meta_max: "Max",
                view_meta_fitMin: "FitMin",
                view_meta_fitMax: "FitMax",
                view_meta_required: "Please enter the view meta",
                attribute_type_input: "Input",
                attribute_type_switch: "Switch",
                attribute_type_camera: "Camera",
                attribute_type_radio: "Status",
                unit: "Unit",
                unit_placeholder: "Please enter the unit",
                icon: "Icon",
                icon_placeholder: "Select Icon",
                icon_search_placeholder: "Search Icon",
                panel_display: "Display",
                allow_operation: "Allow Operation",
                data_persistence: "Data Persistence",
                robotIp: "Robot IP",
                cameraIp: "Live URI",
                del_message: "Are you sure you want to delete this attribute? This action can not be undone.",
                setting: "Data Setting",
                empty_property: "No attribute",

                custom: "Custom",

                sunlight_warning: "If this property is enabled, be sure to set the geographic information.",

                group: "Group",
                group_placeholder: "Please select group",

                follow: "follow",
                unfollow: "unfollow",
                start_time: "Start Time",
                end_time: "End Time",
                select_time: "Select time",
                daily_start: "Daily start time",
                daily_end: "Daily end time",
                create_jobs: "Create Jobs",
                no_jobs: "No timed Jobs",
            },
            parameter: {
                add: "Add Parameter",
                parameter: "Parameter",
                name: "Parameter Name",
                alias: "Parameter Alias",
                type: "Parameter Type",
                types: {
                    string: "String",
                    number: "Number",
                    time: "Time",
                },
                value: "Parameter Value",
                time: "Time",
                string: "String",
                number: "Number",
                error_name: "Please enter the parameter name",
                error_alias: "Please enter the display name of the parameter",
                error_value_number: "Please enter the numeric parameter value",
                error_value_text: "Please enter the text parameter value",
                error_value_time: "Please enter the time parameter value",
                save_success: "Parameters saved successfully",
                operation_success: "Parameter operation successfully",
                del_message: "Are you sure you want to delete this parameter? This action can not be undone.",
                delete_success: "Parameter deleted successfully",
                launch_success: "The parameters were sent successfully",
                sure: "Are you sure to send down all configuration parameters?",
                empty: "No parameters",
                content:
                    "No device is connected and cannot be issued immediately. Submitted changes are saved on the server side and the server will automatically issue the submitted changes after the device is connected.",

                // 状态组件
                status: {
                    close: "Close",
                    open: "Open",
                    stop: "Stop",
                },
                menu: {
                    edit: "Edit",
                    close: "Close",
                    open: "Open",
                    stop: "Stop",
                },
            },
            tasks: {
                add: "Add Task",
                task: "Task",
                name: "Task Name",
                type: "Timing Syntax Type",
                daily: "Timing Settings (daily)",
                basic: "Basic",
                advanced: "Advanced",
                cronExps: "Cron Expression",
                desc: "Description",
                desc_placeholder: "Please enter the description",
                add_micro_task: "Add Micro Task",
                cron_tip: "When you set basic mode, all the rules will execute every day.",
                trigger: "Trigger Immediately",
                empty: "No task",
                callback: "Operation Callback",
                errorCallback: "Please enter the operation callback function",
                errorCronExps: "Please enter the cron expression",
                del_message: "Are you sure you want to delete this task? This action can not be undone.",
                trigger_confirm: "Are you sure you want to trigger [{name}] immediately?",
            },
            cron: {
                Seconds: {
                    name: "Seconds",
                    every: "Every second",
                    interval: ["Every", "second(s) starting at second"],
                    specific: "Specific second (choose one or many)",
                    cycle: ["Every second between second", "and second"],
                },
                Minutes: {
                    name: "Minutes",
                    every: "Every minute",
                    interval: ["Every", "minute(s) starting at minute"],
                    specific: "Specific minute (choose one or many)",
                    cycle: ["Every minute between minute", "and minute"],
                },
                Hours: {
                    name: "Hours",
                    every: "Every hour",
                    interval: ["Every", "hour(s) starting at hour"],
                    specific: "Specific hour (choose one or many)",
                    cycle: ["Every hour between hour", "and hour"],
                },
                Day: {
                    name: "Day",
                    every: "Every day",
                    intervalWeek: ["Every", "day(s) starting on"],
                    intervalDay: ["Every", "day(s) starting at the", "of the month"],
                    specificWeek: "Specific day of week (choose one or many)",
                    specificDay: "Specific day of month (choose one or many)",
                    lastDay: "On the last day of the month",
                    lastWeekday: "On the last weekday of the month",
                    lastWeek: ["On the last", " of the month"],
                    beforeEndMonth: ["day(s) before the end of the month"],
                    nearestWeekday: ["Nearest weekday (Monday to Friday) to the", "of the month"],
                    someWeekday: ["On the", "of the month"],
                },
                Week: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                Month: {
                    name: "Month",
                    every: "Every month",
                    interval: ["Every", "month(s) starting in"],
                    specific: "Specific month (choose one or many)",
                    cycle: ["Every month between", "and"],
                },
            },
            device: {
                current_devices: "Current Devices",
                device_history: "Device History",

                device_id: "Device ID",
                device_alias: "Device Alias",
                device_network: "Network Type",
                device_status: "Status",
                last_modified: "Last Modified",
                operation: "Operation",

                unbind: "Unbind",
                program: "Program",

                bind_success: "Bind successfully",
                bind_device: "Bind Device",
                bind_btn: "Bind",
                bind_placeholder: "Please input device ID",

                unbind_message: "Are you sure to unbind this device?",
                unbind_success: "Unbind successfully",
                rebind: "Rebind",
                no_history: "No Historical device",

                online: "Online",
                offline: "Offline",
                update_confirm: "Update Confirm",

                script_placeholder: "Please input script",
                script_library: "Script Library",
                script_library_logs: "Script Library Logs",
                more: "More",

                edit_device: "Edit device",
                input_device_alias_placeholder: "Please input device alias",
                input_device_remark_placeholder: "Please input device remark",
                alias_tip:
                    "This operation may affect device functions. Ensure that you have understood this operation.",

                remark: "Remark",
                device_name: "Device Name",
                hardware_version: "Hardware Version",
                firmware_version: "Firmware Version",
                network_type: "Network Type",
                current_memory: "Current Memory",
                history_memory: "History Memory",
                script_capacity: "Script Capacity",
                firmware_update: "Firmware Update",
                copy_success: "Copy successfully",
                del_script: "Delete Script",
                del_success: "Delete successfully",
                confirm_label: "Confirm",

                file_select: "Select File",
                save: "Save",
                rollback: "Rollback",
                script_empty: "Script cannot be empty",
                save_success: "Save successfully",
                rollback_success: "Rollback successfully",
                install_success: "Install successfully",
                add_script: "Add Script",
                add: "Add",
                tips: "Tips",
                script_name_enter: "Please enter the script name",
                script_name_validate: "Please enter the script name",
                script_name_exist: "The script name already exists",
                tabs_alert: "Please select the script you want to view from the left column",

                device_info: "Device Info",
                copy_device_id: "Copy Device ID",
                install: "Install",

                generate_code: "Code Generation",
                loading_code_templates: "Import Template",
                save_code: "Save as Template",
                save_script: "Save",
                edit_success: "Edit successfully",
                enter_code_name: "Please enter the template name",
                enter_group_name: "Please enter the group name",
                select_group: "Please select a group",
                code_templates: "Code Templates",
                add_group: "Add Group",
                general_templates: "General templates",
                custom_templates: "Custom templates",
                custom_group: "Custom Groups",
                group: "Group",
                quick_Add: "Save Code Template",
                change_name: "Change the script name",
                save_directly: "Save directly",
                no_code: "No corresponding code module",
                group_name_empty: "Group name cannot be empty",
                code_name_validate: "Please enter the code name",
                untitled: "Untitled",

                search_xterm: "Search",
            },
            chart: {
                start: "Start Date",
                end: "End Date",
                to: "To",
                last_7_days: "L7D",
                last_3_days: "L3D",
                yesterday: "Yesterday",
                today: "Today",
                last_month: "LM",
                last_3_months: "L3M",
                last_6_months: "L6M",
                last_24_hours: "L24H",
                search: "Search",
                download: "Export CSV",
                date_error: "Please select start date or end date",
                data_error: "Please select to download data",
            },
            template: {
                import: "Import",
                import_confirm_title: "Confirm Import",
                import_tips:
                    "This operation will modify existing node configuration options and may cause all functions to be unavailable. Non-technical personnel should not perform this operation. Please confirm that you are aware that this operation is irreversible and cannot be undone.",
                import_mode: "Path",
                import_mode_template: "Import Template",
                import_mode_file: "Import File",
                select_template: "Template",
                select: "- Select -",
                select_file: "Select File",
                template_upload_tip: "Drop file here or click to upload",
                template_upload_limit: "Only json file is supported",
                template_module: "Modules",
                module: "Modules",
                modules: {
                    properties: "Properties",
                    parameters: "Parameters",
                    crontabs: "Timed Task",
                },
                mode: "Mode",
                merge: "Merge",
                coverage: "Coverage",
                reset: "Reset",

                export: "Export",
                export_mode: "Path",
                export_mode_template: "Save as Template",
                export_mode_file: "Export to Local",
                template_group: "Group",
                template_name: "Name",
                file_name: "Name",
                download: "Download",
                save: "Save",

                add_group: "Add Group",
                group_config: "Group Config",
                all: "All",
                group_name: "Group Name",
                temp_placeholder: "Please enter the template name",
                name_error: "Please enter the template name",
                edit_group: "Edit",
                del_group: "Delete",

                from: "From",
                updated_at: "Updated At",
                temp_search_placeholder: "Search by name",
                operation: "Operation",
                template_type: "Type",
                public_group: "Public",
                private_group: "Private",
                public_template: "Public",
                private_template: "Private",
                edit_template: "Edit",
                view_template: "View",
                group: "Group",
                creator: "Creator",
                organization: "Organization",
                template_content: "Content",
                template_content_placeholder: "Please enter the template content",
                template_content_object: "Template content must be a object",
                empty_group: "No Group",
                group_switch: "Templates",
                create_template: "Create",
                remark: "Remark",
                remark_placeholder: "Please enter the remark",
                status: "Status",
                untitled: "Untitled",
                copy: "Copy",
                copy_success: "Copy successfully",
            },
            setting: {
                // 地理
                locate: "Locate",
                cancel: "Cancel",
                geo_reset: "Reset Geo?",
                coordinate: "Coordinate",
                current_coordinate: "Local Coordinates",

                // 分组
                group: "Group",
                no_group: "No Group",
                search_node: "Search",
                setting: "Setting",
                empty_node: "No Node",
                group_name_error: "Group name cannot be empty",
                group_name_placeholder: "Please enter the group name",
                group_empty: "No Group",

                no_match: "No Matched Nodes",
                other_dashboard_match: "Other Dashboard Matched",
                dashboard: "Dashboard",
                search_dashboard: "Search Dashboard",

                base: "Base",
                plugin_extend: "Plugin Extend",

                // sigrow
                sigrow_api_placeholder: "Please enter the Sigrow API Key (Optional)",
                sigrow_central_placeholder: "Please enter the Sigrow Central ID",
            },
            // 灌溉任务
            irrigate: {
                alert: "New Task is Syncing...",
                task_list: "Task List",
                add_task: "Add",

                // 列表字段
                index: "Index",
                name: "Name",
                type: "Type",
                duration: "Duration",
                irrigate_duration: "Irrigate Duration(Area)",
                status: "Status",
                operation: "Operation",

                timing: "Mix-Timing",
                integral: "Mix-Integral",
                fertilizer: "Storage-Fertilizer",
                storage_timing: "Storage-Timing",
                storage_integral: "Storage-Integral",
                channel_timing: "Channel-Timing",

                trigger: "Trigger",
                edit: "Edit",
                edit_time: "Edit Time",
                delete: "Delete",
                delete_confirm: "Confirm Delete?",
                copy: "Copy",
                trigger_success: "Trigger Success",
                trigger_failed: "Trigger Failed",
                enabled_failed: "Enabled Failed",
                // 有一个任务存在冲突
                enabled_conflict: "Conflict With Task【{name}】, Time【{time}】",
                self_conflict: "Time Conflicts With Self, Time【{time}】",
                max_should_be_greater_than_min: "Max should be greater than Min",
                min_should_be_less_than_max: "Min should be less than Max",
                time_should_be_in_range: "Time should be in range",

                // 弹窗内容
                dialog_edit_title: "Edit Task",
                dialog_add_title: "Add Task",
                dialog_copy_title: "Copy Task",
                name_placeholder: "Please enter the task name",

                per_duration: "Duration",
                // 定时灌溉
                timing_params: "Timing Parameters",
                time: "Time",
                time_placeholder: "Please select the time",
                duration_text: "{time}",
                irrigate_to_drainage: "Irrigate to Drainage",
                minute: "Minutes",
                second: "Seconds",
                select_time: "Select Time",

                // 公共参数
                common_params: "Common Parameters",
                target_ec: "Target EC",
                target_ec_placeholder: "Please enter the target EC",
                ec_pump_speed_ratio: "PSR",
                ec_pump_speed_ratio_full: "EC Pump Speed Ratio（B Pump Speed / A Pump Speed）",
                ec_pump_speed_ratio_placeholder: "Please enter the EC Pump Speed Ratio",
                ph_pump_speed: "PPR",
                ph_pump_speed_full: "PH Pump Speed",
                ph_pump_speed_placeholder: "Please enter the pH Pump Speed",
                irrigate_area: "LRAs",
                irrigate_area_full: "Lateral Move Irrigation Areas",
                io_text: "No.{io} Valve",
                add_io: "Add",
                irrigate_interval: "Irrigate Interval",
                mode: "mode",
                pump_speed: "{name} Pump Speed",
                pump_speed_placeholder: "Please enter the {name} Pump Speed",

                // 积分灌溉
                integral_params: "Integral Parameters",
                integral_duration: "Integral Duration",
                integral_duration_text: "To",
                integral_threshold: "Integral Threshold",
                integral_threshold_placeholder: "Please enter the integral threshold",
                min_integral_interval: "Min Integral Interval",
                max_integral_interval: "Max Integral Interval",
                instant_integral: "Instant Integral",
                instant_integral_placeholder: "Please enter the instant integral",
                // 积分灌溉 - 首次灌溉
                first_irrigate_duration: "First Irrigate Duration",
                first_irrigate_duration_placeholder: "Please enter the first irrigate duration",
                first_irrigate_ec: "First Target EC",
                first_irrigate_ec_placeholder: "Please enter the first target EC",
                enable_first_irrigate: "Enable First Irrigate",
                trigger_mode: "Mode",
                timing_trigger: "Timing",
                timing_trigger_tip: 'The start time of the "integral_duration"',
                integral_trigger: "Integral",
                integral_trigger_tip: "The accumulated light integral after the last irrigation",
                first_integral_threshold: "First Integral Threshold",
                first_latest_trigger_time: "First Latest Trigger Time",
                placeholder: "Please complete {field}",
                first_latest_trigger_time_tip: "The setting value must be within the integral_duration",
                // 时长计算方式
                duration_type: "Duration Type",
                quantitative: "Quantitative",
                integral_to_irrigate_duration: "Integral to Irrigate Duration",
                soil_water_feedback: "Soil Water Feedback",
                soil_water_feedback_tip: "",
                min_irrigate_duration: "Min Irrigate Duration",
                max_irrigate_duration: "Max Irrigate Duration",
                conversion_factor: "Conversion Factor",
                soil_water_threshold: "Soil Water Threshold",

                max_interval_invalid_time: "Max Interval Invalid Time",
                max_interval_invalid_time_tip: "",
                advanced: "Advanced",
                min_target_ec: "Target EC Lower Limit",
                max_target_ec: "Target EC Upper Limit",
                debug_extend: "Debug Extend",
                // 配肥
                fertilizer_params: "Fertilizer Parameters",
                fertilizer_type: "Fertilizer Type",
                timing_fertilizer: "Timing Fertilizer",
                fertilizer_time: "Fertilizer Time",
                fertilizer_duration: "Fertilizer Duration",
                fertilizer_duration_placeholder: "Please select the fertilizer duration",
                liquid_level_fertilizer: "Liquid Level Fertilizer",
                start_liquid_level: "Start Liquid Level",
                stop_liquid_level: "Stop Liquid Level",
                meter: "Meter",

                // 计算灌溉时长
                calculate_irrigate_duration: "Calculate Irrigate Duration",
                irrigate_volume: "Irrigate Volume",
                irrigate_volume_placeholder: "Please enter the irrigate volume",
                dripper_rate: "Dripper Rate",
                dripper_rate_placeholder: "Please enter the dripper rate",
                irrigate_volume_unit: "mL/Pot",
                dripper_rate_unit: "L/h/Pot",
            },
        },

        conf: {
            group: "Node Grouping",
            create_node: "Create Node",
            create_dashboard: "Create Dashboard",
            group_manage: "Group Manage",
            permissions: "Permissions Granted",
            edit_dashboard: "Edit Dashboard",
            delete_dashboard: "Delete Dashboard",
            no_node: "The current grouping does not have any nodes",
            duplicate_group_names: "Duplicate group names",
            dashboard_switch: "Dashboard",

            total: "(<b>{total}</b> Nodes)",
            search_placeholder: "Search",
        },
        manage: {
            title: "Nodes",
        },

        // 公共分组组件
        group: {
            add: "Add",
            search_placeholder: "Please enter the name of the group",
            title_placeholder: "Group Name",
            title: "Group",
            operation: "Operation",
        },

        // 节点设备类型
        type: {
            sensor: "Sensor",
            controller: "Controller",
            fertigation: "Fertigation",
            drainage: "Drainage",
            other: "Other",
            scale: "Scale",
        },
    },
};
