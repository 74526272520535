import Icon from "@/components/common/SvgIcon.vue"; // svg component
import { App } from "vue";

// register globally
const requireContext = require.context("@/assets/img/icons", false, /\.svg$/);
requireContext.keys().forEach(requireContext);

export default function install(app: App) {
    app.component("svg-icon", Icon);
}
