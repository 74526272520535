import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/enterprise",
        name: "enterprise",
        redirect: "/enterprise/member",
        component: () => import("@/views/enterprise/Index.vue"),
        meta: {
            title: "企业管理 - Enterprise",
            showInNav: true,
            sort: 40,
            name: t("nav.enterprise"),
            icon: "enterprise",
        },
        children: [
            {
                path: "member",
                name: "enterprise-manage-member",
                component: () => import("@/views/enterprise/Members.vue"),
                meta: {
                    title: "成员管理 - Member Management",
                    showInNav: true,
                    requiredAuth: false,
                    slug: "nav_member",
                    name: t("nav.enterprise_member"),
                    icon: "member",
                    permissions: ["manage_member"],
                    activeMenu: "enterprise",
                },
            },
            {
                path: "role",
                name: "enterprise-manage-role",
                component: () => import("@/views/enterprise/Roles.vue"),
                meta: {
                    title: "角色管理 - Role Management",
                    showInNav: true,
                    requiredAuth: false,
                    slug: "nav_role",
                    name: t("nav.enterprise_role"),
                    icon: "role",
                    permissions: ["manage_role"],
                    activeMenu: "enterprise",
                },
            },
            {
                path: "logs",
                name: "enterprise-user-log",
                component: () => import("@/views/enterprise/UserLogs.vue"),
                meta: {
                    title: "操作日志 - User Logs",
                    showInNav: true,
                    requiredAuth: false,
                    slug: "nav_logs",
                    name: t("nav.enterprise_log"),
                    icon: "log",
                    permissions: ["list_logs"],
                    activeMenu: "enterprise",
                },
            },
            // {
            //     path: "permission/:type/:id?",
            //     name: "enterprise-manage-permission",
            //     component: () => import("@/views/enterprise/components/Permissions.vue"),
            //     meta: {
            //         title: "权限管理 - Permission Management",
            //         requiredAuth: false
            //         slug: "nav_permission",
            //         name: t("nav.enterprise_permission"),
            //         permissions: ["nav_permission"],
            //     },
            // },
        ],
    },
];
