module.exports = {
    // 应用标识
    APP_NAME: "deepberry",
    APP_TITLE: "Deepberry",

    // 侧边栏
    ACTIVE_MODULES: {
        // 黑名单模式
        exclude: [],
        // 白名单模式
        include: [],
    },

    // 默认语言和时区
    DEFAULT_LOCALE: "zh-cn",
    DEFAULT_TIMEZONE: "Asia/Shanghai",

    // 登录界面
    OFFICIAL_SITE: "WWW.DEEPBERRY.CN",
    ABOUT_PAGE: "https://www.deepberry.cn/about",
    linkDetail: "https://www.deepberry.cn/about",
};
