export default {
    map: {
        empty: "该园区暂无地图，",
        edit: "编辑地图",
        create: "前往创建",
        refresh: "刷新",
        edit_node: "节点编辑",
        bind_node: "绑定节点",
        bind_prop: "绑定属性",
    },
};
