export default {
    // 帮助中心
    help: {
        doc: {
            title: "帮助文档",
            back_list: "返回列表",
            back_home: "返回首页",
            search: "搜索",
            doc_list_title: "深莓智能帮助中心",
            doc_list_welcome: "欢迎来到深莓智能帮助中心！",
            contact_us: "联系我们",
            update_time: "更新时间",
            scan: "用手机扫码访问",
            phone_read: "手机阅读",
            no_group: "未分组",
            no_data: "暂无文档",
        },
    },
};
