export default {
    // 目录
    nav: {
        home: "首页",

        overview: "总览",
        overview_management: "园区管理",
        overview_park_detail: "园区详情",
        overview_map: "园区规划",
        overview_dataview: "数据透视",
        overview_dashboard: "监控大盘",
        overview_device: "设备中心",

        sensor: "生态监测",
        sensor_basic: "传感器",
        sensor_control: "控制器",
        sensor_prop: "实时读数",
        sensor_task: "定时任务",
        sensor_irrigate: "灌溉助手",

        // AI Grow
        aigrow: "AI Grow",
        aigrow_conf: "果园配置",
        aigrow_gardens: "果园概览",
        aigrow_ripen: "果熟识别",
        aigrow_stomata_camera: "气孔相机",

        erp: "综合管理",
        es: "电子秤",
        fertilizer: "配肥工单",

        enterprise: "企业管理",
        enterprise_member: "成员管理",
        enterprise_role: "角色管理",
        enterprise_log: "操作日志",
        enterprise_permission: "权限管理",

        // 开发配置
        insights: "开发配置",
        insights_controller: "节点配置",
        insights_node: "园区配置",
        insights_template: "模板配置",
    },
    // 头部
    header: {
        change_organization: "切换组织",
        message: "消息中心",
        profile_settings: "资料设置",
        doc: "文档中心",
        logout: "退出登录",
        help: "帮助中心",
        current: "当前",
        country: "时区",

        support: {
            title: "支持",
            before: "售前咨询",
            after: "售后支持",
            tech: "技术支持",
        },

        node: {
            dashboard: "园区",
        },
    },
    // 鉴权与404等
    system: {
        message: {
            has_no_right: "您没有该页面的访问权限.",
            back_to_previous_page: "返回上一页",
            page_not_found: "抱歉，您访问的页面不存在",
            back_to_home: "返回首页",
            upload_success: "上传成功",
            copy_success: "复制成功",
            del_success: "删除成功",
            success: "提交成功",
        },
        messagebox: {
            title: "消息",
            confirm: "确认",
            cancel: "取消",
            reset: "重置",
            delete: "删除",
            close: "关闭",
            save: "保存",
            print: "打印",
            download: "下载",
        },
        notify: {
            success_title: "操作成功",
            error_title: "操作失败",
            title: "操作",
        },
        form: {
            select_is_required: "必须选择",
            field_is_required: "不能为空",
            field_is_not_valid: "格式不正确",
            field_is_out_of_range: "超出范围",
            update_successfully: "更新成功",
            search_placeholder: "请输入关键词搜索",
            del: "确认删除该条记录？",
        },
    },
};
