export default {
    // 薪资管理
    salary: {
        stat: {
            title: "工资统计",
        },
        detail: {
            title: "工资明细",
        },
        setting: {
            title: "工资设置",
        },
        account: {
            title: "采摘账号",
        },
        manage: {
            title: "电子秤管理",
            salary_summary: "工资统计",
            salary_detail: "工资明细",
            salary_setting: "工资设置",
            pick_account: "采摘账号",
            cloud_scale_manage: "云称管理",
            property_manage: "参数配置",
            export: "导出数据",
            base_salary: "基础工资配置",
            base_salary_success: "基础工资配置成功",
            base: "基础工资",
            rmb: "元",
            piecework: "计件提成配置",
            piecework_success: "计件提成配置成功",
            proportion: "提成比例",
            unit: "元/千克",
            scale: "电子秤配置",
            add_scale: "添加电子秤",
            add_scale_success: "电子秤配置成功",
            edit_scale: "编辑电子秤",
            scale_logs: "配置记录",
            add_wage: "编辑额外工资",
            import: "导入完成",
            import_success: "导入成功",
            import_total: "总导入数",
            import_fail: "导入失败",
            export_success: "导出成功",
            all_dashboards: "全部园区",
            data_chart: "数据图表",
            dashboard: "园区",
            to: "至",
            time: "时间",
            no: "无",
            keyword: "搜索关键字",
            nodata: "暂无数据",
            num: "采摘量",
        },
        table: {
            date: "日期",
            name: "姓名",
            phone: "手机号码",
            piecework: "计件量（g）",
            piece_rate: "计件工资（元）",
            base_wage: "基础工资（元）",
            additional_wages: "额外工资（元）",
            wages: "合计工资（元）",
            remark: "备注",
            id: "序号",
            group: "分组",
            action: "操作",
            edit: "编辑",
            total: "总计件量（g）",
            total_salary: "总合计工资（元）",
            scales: "电子秤ID",
            logs: "操作记录",
            user: "配置人",
            value: "工资（元）",
        },
        form: {
            group: "分拣组",
            id: "电子秤ID",
            message_group: "请输入分拣组名称",
            message_id: "请填写电子秤ID",
        },
    },
    // 临时工管理
    workers: {
        manage: {
            title: "工人管理",
            export: "导入名单",
            add: "新增员工",
            edit: "编辑员工",
            template: "下载Excel模版",
            fail: "导入失败名单下载",
            edit_tips: "是否进行员工信息的修改？",
        },
        table: {
            name: "姓名",
            phone: "手机号码",
            id: "身份证",
            action: "操作",
            edit: "编辑",
            card: "银行卡号",
            bank: "开户行",
            number: "工号",
            message_name: "请输入姓名",
            message_id: "请填写身份证号码",
            message_phone: "请填写手机号码",
            message_bankName: "请填写开户银行名称",
            message_cardNo: "请填写银行卡号",
        },
    },
};
