export default {
    // 账号中心
    account: {
        // 登录
        login: {
            // 标题
            title: "综合管理系统",

            // TAB
            by_pwd: "密码登录",
            by_sms: "短信登录",

            // 表单
            username: "账号",
            password: "密码",
            password_length_limit: "密码长度为{min}-{max}位",

            phone: "手机号",
            code: "验证码",
            get_code: "获取验证码",
            code_sent: "验证码已发送",
            sms_count_limit: "验证码获取次数已达上限",
            sms_get_warning: "您目前已经获取了{count}次短信验证码，每天的获取上限为{max}次",

            locked: "账号已被锁定，请24小时后再试",
            // 提示尝试次数
            error_count_limit: "您已经连续尝试登录{count}次，超过{max}次后账号将被锁定",

            btn_login: "登录",
            btn_logout: "登出",
            already_login: "您已登录。",
            btn_back: "返回",

            // 消息
            success_login: "登录成功",
        },
        logout: "退出登录",
        confirm_logout: "确认退出登录？",
        profile_settings: "资料设置",
    },
    // 个人中心
    user: {
        // 资料设置
        profile: {
            title: "用户设置",
            no_org: "暂无组织",

            avatar: "头像",
            name: "昵称",

            password: "密码",
            password_settings: "密码设置",
            security_center: "安全中心",
            old_password: "当前密码",
            new_password: "新密码",
            password_not_same: "两次密码不一致",
            password_length: "密码长度为6-20位",

            preference_settings: "偏好设置",
            locale: "语言",
            timezone: "时区",
        },
        // 组织切换
        organization: {
            title: "工作组织",
            change_organization: "切换组织",

            is_null: "你尚不在任何工作组织中",

            select: "请选择需要审查的组织",
        },
        // 安全中心
        security: {
            title: "安全中心",
            action: "操作日志",
            login: "登录痕迹",
            page: {
                organization: "组织",
                user: "用户",
                time_range: "时间范围",
                start_time: "选择开始时间",
                end_time: "选择结束时间",
                app: "应用",
                app_placeholder: "输入应用名称",
                resource: "资源",
                resource_placeholder: "输入资源代称",
                resource_id: "资源ID",
                resource_id_placeholder: "输入资源ID",
                action: "动作",
                action_placeholder: "输入动作代称",
                search: "搜索",
                search_placeholder: "从IP／用户代理(UA)／描述／路径中检索",
                search_login_placeholder: "从IP / 用户代理(UA)中检索",
                desc: "描述",
                time: "时间",
                ip: "IP地址",
                status: "状态",
                success: "成功",
                fail: "失败",
            },
        },
    },
};
