export default {
    map: {
        empty: "The Dashboard has no map, ",
        edit: "Edit",
        create: "Create",
        refresh: "Refresh",
        edit_node: "Edit Node",
        bind_node: "Bind Node",
        bind_prop: "Bind Property",
    },
};
