import { $titan2, $next } from "@/utils/api";
import { DashboardItem } from "@/types/dashboard";

// 获取园区列表信息
export function getDashboards(params: any) {
    return $titan2().get(`/sensor/dashboard`, {
        params,
    });
}

// 创建园区
export function createDashboard(data: DashboardItem) {
    return $titan2().post(`/sensor/dashboard`, data);
}

// 查看园区
export function getDashboard(id: number) {
    return $titan2().get(`/sensor/dashboard/${id}`);
}
// 删除园区
export function deleteDashboard(id: number) {
    return $titan2().delete(`/sensor/dashboard/${id}`);
}
// 更新园区
export function updateDashboard(id: number, data: DashboardItem) {
    return $titan2().put(`/sensor/dashboard/${id}`, data);
}

// 获取园区用户列表
export function getDashboardUsers(params) {
    return $titan2().get(`/sensor/dashboard/user`, { params });
}

// 删除某人的园区权限
export function deleteDashboardPermission(params) {
    return $titan2().delete(`/sensor/dashboard/user`, {
        params,
    });
}

// 添加某人的园区权限
export function addDashboardPermission(data) {
    return $titan2().post(`/sensor/dashboard/user`, data);
}

// 获取专家预设列表
export function getPresets(params) {
    return $titan2().get(`/sensor/indicator/preset`, {
        params,
    });
}
export function getPreset(id) {
    return $titan2().get(`/sensor/indicator/preset/${id}`);
}

// 更新节点指标
export function updateNodeIndicator(node_id, data) {
    return $titan2().post(`/sensor/node/${node_id}/indicator`, data);
}

// 获取节点指标
export function getNodeIndicator(node_id) {
    return $titan2().get(`/sensor/node/${node_id}/indicator`);
}

// 获取园区设备
export function getDashboardDevices(orgId: number) {
    return $next().get(`/organization/${orgId}/device-count`);
}

// 通知ws刷新org数据
export function noticeWs(orgId: number) {
    return $next().post(`/organization/${orgId}/refresh`);
}
