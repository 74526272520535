import { $cms } from "@/utils/api";

// 加载全局配置
export async function getConf() {
    const res = await $cms().get("/titan/conf");
    return res.data.data;
}

// 更新头像
export function uploadAvatar(formdata) {
    return $cms().post("/system/upload/via/titan", formdata);
}

// 加载文档
export function getDocument(key: string) {
    return $cms().get("/misc/document", { params: { key } });
}

// 获取文档列表
export function getDocuments() {
    return $cms().get(`/misc/documents`);
}

// 通过id获取文档
export function getDocumentById(id: number) {
    return $cms().get(`/misc/document/${id}`);
}

// 加载公告
export async function getAc(key: string) {
    const res = await $cms().get(`/misc/announcement`, { params: { key } });
    return res.data?.data || {};
}

// 批量加载公告
export async function getAcs(key: string) {
    const res = await $cms().get(`/misc/announcements`, { params: { key } });
    return res.data.data;
}

// 获取时区
export function getTimeZoneList(params) {
    return $cms().get("/titan/conf/timezone", { params });
}
