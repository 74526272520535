// 导航权限
export const navigationByPermission = {
    // TODO: 需要根据权限接口显示的导航 t
    admin: ["insights", "enterprise", "erp", "salary", "workers", "document"],
    member: ["insights", "document"],
};

// 子账号初始密码
export const defaultPassword = "123456";

// 百度地图AK
export const baiduMapAK = "RkLUzy5xZC1GRQd7gsTgnY1P7TKFeGAk";
// export const baiduMapAK = "T6dmodStHFDGQBNhsyGYVMfi2FA0n6jC";
