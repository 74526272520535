export default {
    // 账号中心
    account: {
        // 登录
        login: {
            // 标题
            title: "Integrated Management System",

            // TAB
            by_pwd: "Password Login",
            by_sms: "SMS Login",

            // 表单
            username: "Username",
            password: "Password",
            password_length_limit: "Password length is {min}-{max}",

            phone: "Phone",
            code: "Code",
            get_code: "Captcha",
            code_sent: "Captcha sent",
            sms_count_limit: "SMS sending times have reached the limit",
            sms_get_warning: "You have got {count} SMS Captcha, the limit is {max} per day",

            locked: "Account has been locked, please try again after 24 hours",
            error_count_limit: "You have tried to login {count} times, the account will be locked after {max} times",

            btn_login: "Login",
            btn_logout: "Logout",
            already_login: "You have already logged in.",
            btn_back: "Back",

            // 消息
            success_login: "Login Success",
        },
        logout: "Logout",
        confirm_logout: "Are you sure to logout?",
        profile_settings: "Profile",
    },
    // 个人中心
    user: {
        // 资料设置
        profile: {
            title: "Profile",
            no_org: "No Organization",

            avatar: "Avatar",
            name: "Nickname",

            password: "Password",
            password_settings: "Password Settings",
            security_center: "Security Center",
            old_password: "Old Password",
            new_password: "New Password",
            password_not_same: "Two passwords are inconsistent",
            password_length: "Password length is 6-20",

            preference_settings: "Preference Settings",
            locale: "Language",
            timezone: "Timezone",
        },
        // 组织切换
        organization: {
            title: "Organization",

            is_null: "You are not in any organization.",

            select: "Select Organization",
        },
        // 安全中心
        security: {
            title: "Security",
            action: "Operation Log",
            login: "Login Trace",
            page: {
                organization: "Organization",
                user: "User",
                time_range: "Time limit",
                start_time: "Select start time",
                end_time: "Select end time",
                app: "Application",
                app_placeholder: "Enter application name",
                resource: "Resource",
                resource_placeholder: "Enter resource name",
                resource_id: "Resource ID",
                resource_id_placeholder: "Enter Resource ID",
                action: "Action",
                action_placeholder: "Enter the name of the action",
                search: "Search",
                search_placeholder: "Retrieve from IP/User Agent (UA)/Description/Path",
                search_login_placeholder: "Retrieve from IP/User Agent (UA)",
                desc: "Description",
                time: "Time",
                ip: "IP address",
                status: "Status",
                success: "Successful",
                fail: "Failure",
            },
        },
    },
};
