/**
 * 请求实例生成器
 * @desc 用于生成请求实例，可以自定义配置，编写请求头等
 */

import axios from "axios";
import User from "@deepberry/titan-web-components/src/utils/user";
import { installSimpleInterceptors, installStandardInterceptors } from "./interceptors";
import * as signalR from "@microsoft/signalr";
import { HttpTransportType } from "@microsoft/signalr";

/**
 * 统一请求构建器
 *
 * @param {*} baseURL
 * @return {*}
 */
function $io(options?: any) {
    const config = {
        baseURL: process.env.VUE_APP_IO_API,

        withCredentials: true,
        headers: {
            Authorization: "Bearer " + User.getToken(),
        },
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installSimpleInterceptors(ins, options);

    return ins;
}

/**
 * io socket
 *
 * @param {*} url
 * @return {*}
 */
function $ios(url) {
    const socketApiUrl = process.env.VUE_APP_IO_WS;

    return new signalR.HubConnectionBuilder()
        .withUrl(`${socketApiUrl}${url}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => User.getToken() as string,
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (_retryContext) => {
                return 5000;
            },
        })
        .build();
}

/**
 * io socket
 *
 * @param {*} url
 * @return {*}
 */
function $nexts(url) {
    // const socketApiUrl = "http://192.168.5.87:5000/";
    // const socketApiUrl = "https://gray.next.deepberry.cn/";
    const socketApiUrl = process.env.VUE_APP_NEXT_WS;

    return new signalR.HubConnectionBuilder()
        .withUrl(`${socketApiUrl}${url}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => User.getToken({ version: 2 }) as string,
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (_retryContext) => {
                return 5000;
            },
        })
        .build();
}

/**
 * cms接口请求构建器
 *
 * @param {*} baseURL
 * @return {*}
 */
function $cms(options?: any) {
    const config = {
        baseURL: process.env.VUE_APP_CMS_API + "api/cms/",

        withCredentials: false,
        headers: {
            Authorization: "Bearer " + User.getToken(),
        },
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

/**
 * titan接口请求构建器
 *
 * @param {*} baseURL
 * @return {*}
 */
function $titan(options?: any) {
    const config = {
        baseURL: process.env.VUE_APP_TITAN_API + "api/titan/",

        withCredentials: false,
        headers: {
            Authorization: "Bearer " + User.getToken(),
        },
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

/**
 * titan接口请求构建器
 *
 * @param {*} baseURL
 * @return {*}
 */
function $titan2(options?: any) {
    const config = {
        baseURL: process.env.VUE_APP_TITAN_API + "api/titan/",
        // baseURL: "https://gray.api.deepberry.cn/api/titan/",

        withCredentials: false,
        headers: {
            Authorization: "Bearer " + User.getToken({ version: 2 }),
        },
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

/**
 * titan接口请求构建器
 *
 * @return {*}
 * @param options
 */
function $next(options?: any) {
    const config = {
        baseURL: process.env.VUE_APP_NEXT_API + "api/",
        // baseURL: "http://192.168.5.87/" + "api/",

        withCredentials: false,
        headers: {
            Authorization: "Bearer " + User.getToken({ version: 2 }),
        },
    };

    // 创建实例
    const ins = axios.create(config);

    // 指定拦截器
    installStandardInterceptors(ins, options);

    return ins;
}

class SSE {
    private readonly url: string;
    private eventSource: EventSource | null = null;

    constructor(url: string) {
        this.url = url;
    }

    connect(): void {
        this.eventSource = new EventSource(this.url);
    }

    disconnect(): void {
        this.eventSource?.close();
    }

    on(eventName: string, callback: EventListenerOrEventListenerObject): void {
        this.eventSource?.addEventListener(eventName, callback);
    }

    off(eventName: string, callback: EventListenerOrEventListenerObject): void {
        this.eventSource?.removeEventListener(eventName, callback);
    }
}

/**
 * notification接口请求构建器
 *
 * @param {*} url 通知接口地址
 * @return {*}
 */
function $notification(url: string): any {
    const notificationApiHost = process.env.VUE_APP_NOTIFICATION_API;
    const notificationApiUrl = notificationApiHost + "api/notification/";

    const es = new SSE(`${notificationApiUrl}${url}`);

    return es;
}

export { $io, $ios, $cms, $titan, $notification, $next, $nexts, $titan2 };
