function getFnStatus($theme, fn) {
    const activeModules = $theme.ACTIVE_MODULES;
    // 优先黑名单模式
    if (activeModules.exclude && activeModules.exclude?.length && activeModules.exclude?.includes(fn)) {
        return false;
    }
    // 其次白名单模式
    if (activeModules.include && activeModules.include?.length && activeModules.include?.includes(fn)) {
        return true;
    }
    return true;
}

export default {
    mounted(el, binding, vnode, prevVnode) {
        const fn = binding.arg;
        const $theme = binding.instance.$theme;
        const status = getFnStatus($theme, fn);
        if (!status) {
            el.style.display = "none";
        }
    },
};
