export default {
    fertilizer: {
        recipe_receive: "配方领用",
        recipe_making: "配方制作",
        add_recipe: "创建配方",
        search_placeholder: "输入关键字按enter进行搜索",
        recipe_content: "配方内容",

        title: "名称",
        edit_title: "修改配方名称",
        description: "使用说明",
        remark: "备注",
        meta: "扩展说明",
        status: "状态",
        operation: "操作",
        view: "查看",
        make: "制作",
        edit: "编辑",
        delete: "删除",
        more: "更多",
        edit_remark: "修改备注",
        delete_recipe_confirm: "确认删除配方吗",
        placeholder: "请输入{field}",
        maker: "制作人",
        expired: "已过期",

        is_public: "是否公开",
        public: "公开",
        assign: "指定",
        add_user: "添加领用人",

        updated_at: "更新时间",
        created_at: "创建时间",

        tabs: {
            desc: "使用说明",
            meta: "扩展说明",
            recipients: "领用人员",
        },

        add_dialog: {
            title_required: "标题必填",
        },

        add_caution: "添加项",
        caution_key: "键",
        caution_value: "值",
        can_not_be_empty: "{field}不能为空",

        expired_at: "授权期限",
        confirm_remove_user: "确认移除该领用人吗？",
        expired_at_placeholder: "请选择授权期限",
        expired_at_success: "授权期限修改成功",
        recipients: "领用人",
    },
};
