export default [
    {
        path: "/webview/dashboard/",
        name: "webviewDashboard",
        component: () => import("@/views/insights/IndexWebview.vue"),
    },
    {
        path: "/webview/dashboard/node-settings/:dashboardId/:nodeId",
        name: "webviewNodeSettings",
        component: () => import("@/views/insights/node/webview/NodeSettings.vue"),
    },
];
