export default {
    sensor: {
        indicator: {
            title: "Readings",
            chart: "Charts",
            empty: "No Data",

            par: "PAR",
            par_integral: "PAR Integral",
            co2: "co2",
            air_temperature: "Air Temperature",
            dew_point: "Dew Point",
            frost_point: "Frost Point",
            air_humidity: "Air Humidity",
            absolute_humidity: "Absolute Humidity",
            VPD: "VPD",
            vpd: "VPD",
            soil_ec: "Soil EC",
            soil_temperature: "Soil Temperature",
            soil_humidity: "Soil Humidity",
            soil_water: "Soil Water",
            pore_ec: "Pore EC",
            discharge_ratio: "Discharge Ratio",
            drainage_ec: "Drainage EC",
            radiation: "Irradiation",
        },

        indicator_types: {
            light: "Light",
            air: "Air",
            heat: "Heat",
            water: "Water",
            soil: "Soil",
            root: "Root",
            plant: "Plant",
        },

        outdoor: {
            OAT: "Temperature",
            Td: "Dew Point",
            RH: "Humidity",
            HPD: "Precipitation",
            Pa: "Pressure",
            WS: "Wind Speed",
            wind_direction: "Wind Direction",
            cloud: "Cloudage",
            title: "Outdoor Env",
            no_geo: "No node geographic information has been set",
            click_to_configure: "click to configure",
            next_24_hour: "Next 24 hours",

            no_data: "No Data",
        },

        device: {
            title: "Device",
            advanced_configuration: "Advanced Configuration",
            empty_device: "No device",
        },

        controls: {
            global: "Global",
            global_warning: "Please operate carefully",
            open: "open",
            close: "close",
            global_tip: "Are you sure you want to {config} all the switches?",
            untitled: "Untitled",
            custom: "Custom",
            selected_all: "Select Group",
            batch_control: "Batch Control",
            empty: "No Switch",
            current_node: "Node",
            group_manage: "Group Management",
            sort_btn_start: "Start Order",
            sort_btn_stop: "Stop Order",
            no_group: "Unclassified",
            group_edit: "Edit Group",
            job_edit: "Edit Job",
            order: "Order",
            group: "Group",
        },
        tasks: {
            title: "Tasks",
            name_edit: "Edit",
            more: "{count} total",
            status: "Status",
            on: "On",
            off: "Off",
            trigger: "Trigger",
            coding: "Coding",
            template: "Template",
            desc: "Description",
            name_placeholder: "Please enter the task name",
            source_data: "Source Data",
            cron_tip: "Multiple rules can be set up simultaneously via microtasks",
            empty_text: "No task",
            cron_title: "Cron Setting",
            untitled: "Untitled",
            success: "Success",
            unknown_error: "Unknown Error",
            all: "All",

            save_as_temp: "Save as Template",
            empty_template: "No template",
            templates: {
                title: "Templates",
                public: "Public",
                user: "Private",
                no_group: "Not grouped",
                insert: "Import",
                description: "Description",
                cron_exps: "Cron",
                no_template: "No Template",
                to_create: "Go to Create Template",
                join_group: "Join Group",
                duplicate_names: "Duplicate timed task names",
                enter_name: "Please enter the name of the timed task",
            },
            logs: {
                title: "Logs",
                log_status: "Status",
                log_time: "Time",
                log_detail: "Detail",
                task_name: "Name",
                empty_log: "No log",
                empty: "No log",
                execution_duration: "Execution Duration",
                remark: "Remark",
                edit_remark: "Edit Remark",

                start: "Start",
                end: "End",

                SUCCESS: "Success",
                FAILED: "Failed",
                unknown: "Unknown",
                undefined: "Unknown",
            },
        },

        irrigates: {
            summary: "Summary",
            crop_balance: "Crop Balance",
            water_change: "Water Change",
            sidebar_title: "Real-time data",
            sidebar_null: "No data",

            // summary
            irrigate: "Irrigation",
            drain: "Drainage",
            discharge_ratio: "Drainage Ratio",
            irrigation_efficiency: "Irrigation Efficiency",

            total_irrigate: "Total Irrigation",
            instant_irrigate: "Instant Irrigation",

            // crop balance
            energy: "Energy",
            biomass_accumulation: "Biomass Accumulation",
        },

        preset: {
            title: "Preset",
            preset_plan: "Preset Plan",
        },

        // 气孔相机
        stomata: {
            dli: "Day Light Integral",
            g_water: "Absolute Humidity",
            vbat: "Battery percentage",
            par: "PAR",
            temp: "Air Temperature",
            humid: "Relative Humidity",
            t_dew: "Dew Point Temperature",
            t_leaf: "Dry Leaf Temperature",
            vpd: "VPD Air",
            history: "History",

            parameter: "Parameter Chart",
            prev: "Previous",
            next: "Next",
            auto: "Auto",
            distance: "Set Distance",
            distance_placeholder: "Please enter the distance",
            next_soon: "Next soon",
            stop: "Stop",
            current_time: "Current Time",
            prev_time: "Prev",
            next_time: "Next",
            empty: "No Photos",
            camera_snapshot: "Camera Snapshot",

            camera_config: "Camera Configuration",
            dry_leaf_config: "Dry Leaf Configuration",
            edit_dry_leaf_position: "Edit Dry Leaf Position",
            point_name_required: "Please enter the point name",
            point_name: "Point Name",
            point_x_required: "Please enter the X coordinate",
            point_x: "X",
            point_y_required: "Please enter the Y coordinate",
            point_y: "Y",
            camera_placeholder: "Please type the camera configuration",

            // 原图
            original: "Original",
            // 温度
            temperature: "Temperature",
            // 气孔
            stomata: "Stomata",
            temperature_error: "Error",
        },

        // 关注指标
        follows: {
            title: "Follows",
            all: "All",
            empty: "No Readings",
            reading: "Focus Readings",
            add: "Add Reading",

            public: "Public",
            set_public: "Set Public",
            set_private: "Set Private",
            follow: "Follow",
            unfollow: "Unfollow",
        },

        // 计算属性
        computed: {
            title: "Computed Reading",
            add: "Add Computed Reading",
            edit: "Edit Computed Reading",
            name: "Property Name",
            display_name: "Display Name",
            name_required: "Please enter the computed reading name",
            display_name_required: "Please enter the computed display name",
            formula: "Formula",
            formula_required: "Please enter the formula",
            formula_placeholder: "Please use $1,$2,... $n replaces the variable",
            formula_preview: "Formula Preview",
            variable_validate: "The variable format is $n",
            variable_unknown: "Presence of unknown variable",
            formula_error: "Formula formatting error",
            prop_key: "Please match props",
            data_set: "Data setting",
            unit: "Unit",
            data_persistence: "Data Persistence",
            prop_match: "Property matching",
            data_type: "Data type",
            normal: "Normal",
            sum: "Sum",
            window: "Window time(s)",
            prop: "Property",
            prop_select: "Please select source property",
            prop_type: "Property type",
            prop_type_select: "Please select property type",
            hour: "Cumulative time(h)",
            depend_tip: "The dependency attribute does not exist. Please re-match",
            del_message: "Are you sure you want to delete this attribute? This action can not be undone.",
        },
    },
};
