export default {
    parkmap: {
        title: "园区地图",
        height: "高度",
        width: "宽度",
        ratio: "比例尺",
        background: "背景图片",
        name: "地图名称",
        area: "地图面积",

        rect: "矩形",
        select_rect: "选择矩形",
        line: "线条",
        select_line: "选择线条",

        rotate: "旋转",
        length: "行长",
        gutter: "间距",
        col: "列数",
        x: "X坐标",
        y: "Y坐标",
        triangle: "三角形",
        select_triangle: "选择三角形",
        padding_left: "左边距",
        padding_top: "上边距",
        padding_right: "右边距",
        padding_bottom: "下边距",

        param_setting: "参数设置",
        // 请先选择元素
        select_element: "请先选择元素",

        // 节点选择
        node_select: "节点",
        dashboard_select: "园区",

        edit: "编辑",
    },
};
