export default [
    {
        path: "/user",
        name: "user",
        redirect: "/user/profile",
        component: () => import("@/views/user/Index.vue"),
        meta: { title: "个人中心 - User" },
        children: [
            {
                path: "profile",
                name: "user-profile",
                component: () => import("@/views/user/Profile.vue"),
                meta: { title: "资料设置 - Profile" },
            },
            {
                path: "organization",
                name: "user-organization",
                component: () => import("@/views/user/Organization.vue"),
                meta: { title: "工作组织 - Organization" },
            },
            {
                path: "security",
                name: "user-security",
                component: () => import("@/views/user/Security.vue"),
                meta: { title: "安全中心 - Security" },
            },
        ],
    },
];
