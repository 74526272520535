export default {
    // 园区管理
    insights: {
        // 节点
        nodes: {
            current_node: "当前节点",
            selection_node: "选择节点",
            title: "园区管理",
            manage: "园区配置",
            type: "类型",

            // 筛选
            sort_btn_start: "开始排序",
            sort_btn_stop: "结束排序",

            // 表单
            add: "添加节点",
            edit: "编辑节点",
            dashboard: "园区",
            name: "名称",
            remark: "备注",
            dashboard_placeholder: "请选择园区",
            name_placeholder: "请输入名称",
            remark_placeholder: "请输入备注",
            search_placeholder: "请输入关键词搜索",

            // 设置
            delete: "删除",
            confirm_delete: "是否确认删除该节点（该操作不可恢复）?",
            confirm_delete_prefix: "是否确认删除",
            confirm_delete_suffix: "节点（该操作不可恢复）?",
            upload_template: "上传模板",
            download_template: "下载模板",
            empty_group: "还没有任何分组",
            to_create: "创建分组",

            // 列表
            untitled: "未命名",
            empty_list: "没有任何节点",

            // 操作
            prop: "属性",
            chart: "图表",
            params: "参数",
            cron: "任务",
            device: "设备",
            node_template: "模板",
            geo: "地理",
            settings: "设置",
            irrigate: "灌溉任务",
        },
        // 园区
        dashboard: {
            current_dashboard: "当前园区",
            selection_dashboard: "选择园区",
            drawer_title: "园区管理",

            dashboard: "园区",
            add: "创建园区",
            edit: "编辑",
            delete: "删除",
            confirm_delete: "确定要删除该园区吗（该操作不可恢复）?",
            add_dashboard: "添加园区",

            search_placeholder: "请输入关键词搜索",
            dashboards: "园区",
            nodes: "节点",

            // 表单
            name: "名称",
            name_placeholder: "请输入名称",
            remark: "备注",
            remark_placeholder: "请输入备注",

            auth: "分配管理人员",
            grant: "添加授权",
            select_user: "请选择",
            unknown: "未知",
            remove: "移除",
            confirm_remove: "确定要移除该成员权限吗？",
            invalid: "<未绑定手机>",
        },
        // 弹出园区
        panel: {
            add: "新增",
            edit: "编辑",
            start: "启动",
            stop: "停止",
            del: "删除",
            tips: "提示",
            copy: "复制并新建",
            enabled: "启用",
            disabled: "禁用",
            action: "操作",
            note: "备注",
            ok: "确定",
            cancel: "取消",
            all: "全选",
            inverse: "反选",
            // 属性
            prop: {
                add: "新增属性",
                edit: "编辑属性",
                attribute_type: "属性类型",
                attribute_type_placeholder: "请选择属性类型",
                attribute_name: "属性名",
                attribute_name_placeholder: "请输入属性名",
                attribute_name_exist: "属性名已存在",
                attribute_display_name: "显示名",
                attribute_display_name_placeholder: "请输入显示名",
                attribute_value: "属性值",
                attribute_value_placeholder: "请输入属性值",
                data_type: "数据类型",
                view_model: "展示类型",
                view_model_placeholder: "请选择展示类型",
                view_meta: "展示设置",
                view_meta_min: "最小值",
                view_meta_max: "最大值",
                view_meta_fitMin: "参考下限",
                view_meta_fitMax: "参考上限",
                view_meta_required: "请设置参考上下限及最大最小值",
                attribute_type_input: "参数",
                attribute_type_switch: "开关",
                attribute_type_camera: "相机",
                attribute_type_radio: "状态",
                unit: "单位",
                unit_placeholder: "请输入单位",
                icon: "图标",
                icon_placeholder: "选择图标",
                icon_search_placeholder: "搜索图标",
                panel_display: "园区展示",
                allow_operation: "允许操作",
                data_persistence: "数据持久化",
                robotIp: "机器人（IP地址）",
                cameraIp: "相机（推流地址）",
                del_message: "您确定要删除吗? 此操作将无法撤消。",
                setting: "数据设置",
                empty_property: "暂不存在任何属性",

                custom: "自定义界面",
                sunlight_warning: "若启用此属性，请务必设置地理信息。",

                group: "分组",
                group_placeholder: "请选择分组",

                follow: "关注",
                unfollow: "取消关注",
                start_time: "开始时间",
                end_time: "结束时间",
                select_time: "选择时间",
                daily_start: "每天开始时间",
                daily_end: "每天结束时间",
                create_jobs: "创建任务",
                no_jobs: "暂无定时任务",
            },
            // 参数
            parameter: {
                add: "添加参数",
                parameter: "参数",
                name: "参数名",
                alias: "参数别名",
                type: "参数类型",
                types: {
                    string: "文本",
                    number: "数字",
                    time: "时间",
                },
                value: "参数值",
                error_name: "请输入参数名",
                error_alias: "请输入参数别名",
                error_value_number: "请输入正确的数字参数值",
                error_value_text: "请输入正确的文本参数值",
                error_value_time: "请输入正确的时间参数值",
                save_success: "参数保存成功",
                operation_success: "参数操作成功",
                delete_success: "参数删除成功",
                del_message: "确定要删除该参数吗？该操作不可撤销。",
                launch_success: "参数下发成功",
                sure: "确定下发所有配置参数吗？",
                empty: "暂无参数",
                content:
                    "!!! 没有连接设备，无法立即下发，提交的改动会保存在服务器端，服务器在设备连接后会自动下发提交的改动。",

                // 状态组件
                status: {
                    close: "关",
                    open: "开",
                    stop: "停",
                },
                menu: {
                    edit: "编辑",
                    close: "关闭",
                    open: "开启",
                    stop: "停止",
                },
            },
            // 任务
            tasks: {
                add: "创建任务",
                task: "任务",
                name: "任务名",
                type: "定时语法类型",
                daily: "定时设置（每日）",
                basic: "基本",
                advanced: "高级",
                cronExps: "定时表达式",
                desc: "描述",
                desc_placeholder: "请输入描述",
                add_micro_task: "添加微任务",
                cron_tip: "基础模式下，每个微任务将每天重复执行；高级模式下，每个微任务将按照定时表达式执行",
                trigger: "立即执行",
                empty: "暂无任务",
                callback: "操作回调",
                errorCronExps: "请输入定时表达式",
                errorCallback: "请输入操作回调函数",
                del_message: "确定要删除该定时任务吗？该操作不可撤销。",
                trigger_confirm: "确定要立即执行任务【{name}】吗？",
            },
            // cron 表达式
            cron: {
                Seconds: {
                    name: "秒",
                    every: "每一秒钟",
                    interval: ["每隔", "秒执行 从", "秒开始"],
                    specific: "具体秒数(可多选)",
                    cycle: ["周期从", "到", "秒"],
                },
                Minutes: {
                    name: "分",
                    every: "每一分钟",
                    interval: ["每隔", "分执行 从", "分开始"],
                    specific: "具体分钟数(可多选)",
                    cycle: ["周期从", "到", "分"],
                },
                Hours: {
                    name: "时",
                    every: "每一小时",
                    interval: ["每隔", "小时执行 从", "小时开始"],
                    specific: "具体小时数(可多选)",
                    cycle: ["周期从", "到", "小时"],
                },
                Day: {
                    name: "天",
                    every: "每一天",
                    intervalWeek: ["每隔", "周执行 从", "开始"],
                    intervalDay: ["每隔", "天执行 从", "天开始"],
                    specificWeek: "具体星期几(可多选)",
                    specificDay: "具体天数(可多选)",
                    lastDay: "在这个月的最后一天",
                    lastWeekday: "在这个月的最后一个工作日",
                    lastWeek: ["在这个月的最后一个"],
                    beforeEndMonth: ["在本月底前", "天"],
                    nearestWeekday: ["最近的工作日（周一至周五）至本月", "日"],
                    someWeekday: ["在这个月的第", "个"],
                },
                Week: ["天", "一", "二", "三", "四", "五", "六"].map((val) => "星期" + val),
                Month: {
                    name: "月",
                    every: "每一月",
                    interval: ["每隔", "月执行 从", "月开始"],
                    specific: "具体月数(可多选)",
                    cycle: ["从", "到", "月之间的每个月"],
                },
            },
            // 设备
            device: {
                current_devices: "当前设备",
                device_history: "历史设备",

                device_id: "设备编号",
                device_alias: "设备别名",
                device_network: "联网类型",
                device_status: "状态",
                last_modified: "最后更新",
                operation: "操作",

                unbind: "解绑",
                program: "编程",

                bind_success: "绑定成功",
                bind_device: "绑定设备",
                bind_btn: "绑定",
                bind_placeholder: "请输入要绑定的设备编号",

                unbind_message: "您确定要解绑该设备吗?",
                unbind_success: "解绑成功",
                rebind: "重新绑定",
                no_history: "暂无历史设备",

                online: "在线",
                offline: "离线",
                update_confirm: "更新待确认",

                script_placeholder: "请输入脚本名称",
                script_library: "脚本库",
                script_library_logs: "历史脚本库",
                more: "更多",

                edit_device: "修改设备",
                input_device_alias_placeholder: "请输入设备别名",
                input_device_remark_placeholder: "请输入设备备注",
                alias_tip: "该操作可能影响设备功能，请确认已了解该行为。",

                remark: "备注",
                device_name: "硬件名称",
                hardware_version: "硬件版本",
                firmware_version: "固件版本",
                network_type: "网络类型",
                current_memory: "当前内存",
                history_memory: "历史内存",
                script_capacity: "脚本容量",
                firmware_update: "固件更新",
                copy_success: "复制成功",
                del_script: "删除脚本",
                del_success: "删除成功",
                confirm_label: "确认",

                file_select: "选择文件",

                save: "保存",
                rollback: "回滚",
                script_empty: "脚本内容不能为空",
                save_success: "保存成功",
                rollback_success: "回滚成功",
                install_success: "安装成功",
                add_script: "添加脚本",
                add: "添加",
                tips: "提示",
                script_name_enter: "请填写脚本名称",
                script_name_validate: "脚本名称不能为空",
                script_name_exist: "脚本名称已存在",
                tabs_alert: "请从左侧栏选择需要查看的脚本",

                device_info: "设备信息",
                copy_device_id: "设备ID",
                install: "安装",

                generate_code: "智能生成代码",
                loading_code_templates: "加载代码模版",
                save_code: "保存为模版",
                save_script: "保存",
                edit_success: "修改成功",
                enter_code_name: "请输入模版名称",
                enter_group_name: "请输入分组名称",
                select_group: "请选择分组",
                code_templates: "代码模版",
                add_group: "添加分组",
                general_templates: "通用模版",
                custom_templates: "自定义模版",
                custom_group: "自定义分组",
                group: "分组",
                quick_Add: "保存为代码模版",
                change_name: "修改脚本名称",
                save_directly: "直接保存",
                no_code: "没有对应代码模块",
                group_name_empty: "分组名称不能为空",
                code_name_validate: "脚本名称不能为空",
                untitled: "未分组",

                search_xterm: "搜索",
            },
            // 图表
            chart: {
                start: "开始时间",
                end: "结束时间",
                to: "至",
                last_7_days: "最近7日",
                last_3_days: "最近3日",
                yesterday: "昨日",
                today: "今日",
                last_month: "最近1个月",
                last_3_months: "最近3个月",
                last_6_months: "最近6个月",
                last_24_hours: "24小时",
                search: "查询",
                download: "导出CSV",
                date_error: "请选择开始时间或结束时间",
                data_error: "请选择下载数据",
            },
            // 模板
            template: {
                import: "导入",
                import_confirm_title: "导入模板",
                import_tips:
                    "此项将对现有节点配置项进行变更，可能导致所有功能异常，非技术人员请不要操作。请确认已知晓，此操作将不可撤销！",
                import_mode: "导入途径",
                import_mode_template: "从模板导入",
                import_mode_file: "从本地导入",
                select_template: "选择模板",
                select: "- 选择 -",
                select_file: "选择文件",
                template_upload_tip: "拖拽文件至此或点击上传",
                template_upload_limit: "请上传JSON模板文件",
                template_module: "模块",
                module: "导入模块",
                modules: {
                    properties: "属性",
                    parameters: "参数",
                    crontabs: "任务",
                },
                mode: "导入模式",
                merge: "合并",
                coverage: "覆盖",
                reset: "重置",

                export: "导出",
                export_mode: "导出途径",
                export_mode_template: "保存为模板",
                export_mode_file: "导出到本地",
                template_group: "模板分组",
                template_name: "模板名称",
                file_name: "模板名称",
                download: "下载模板",
                save: "保存模板",

                add_group: "添加分组",
                group_config: "分组配置",
                all: "全部",
                group_name: "分组名称",
                temp_placeholder: "请输入模板名称",
                name_error: "模板名称不能为空",
                edit_group: "编辑分组",
                del_group: "删除分组",

                // table
                from: "来源",
                updated_at: "更新时间",
                temp_search_placeholder: "请输入模板名称进行搜索",
                operation: "操作",
                template_type: "模板类型",
                public_group: "公共分组",
                private_group: "私有分组",
                public_template: "公共模板",
                private_template: "私有模板",
                edit_template: "编辑模板",
                view_template: "查看模板",
                group: "分组",
                creator: "创建者",
                organization: "组织",
                template_content: "模板内容",
                template_content_placeholder: "请输入模板内容",
                template_content_object: "模板内容必须为对象",
                empty_group: "暂无分组",
                group_switch: "分组切换",
                create_template: "创建模板",
                remark: "备注",
                remark_placeholder: "请输入备注",
                status: "状态",
                untitled: "未分组",
                copy: "复制",
                copy_success: "复制成功",
            },
            // 设置
            setting: {
                // 地理
                locate: "定位至这里",
                cancel: "取消",
                geo_reset: "确认重置地理位置？",
                coordinate: "坐标",
                current_coordinate: "当前坐标",

                // 分组
                group: "分组",
                no_group: "未分组",
                search_node: "搜索节点",
                setting: "设置",
                empty_node: "当前分组没有任何节点",
                group_name_error: "分组名称不能为空",
                group_name_placeholder: "请输入分组名称",
                group_empty: "当前没有任何分组",

                no_match: "没有匹配的节点",
                other_dashboard_match: "其他园区匹配到的节点",
                dashboard: "园区",
                search_dashboard: "搜索园区",

                base: "基础设置",
                plugin_extend: "插件扩展",

                // sigrow
                sigrow_api_placeholder: "请输入Sigrow API Key（非必填）",
                sigrow_central_placeholder: "请输入Sigrow Central ID",
            },
            // 灌溉任务
            irrigate: {
                alert: "新任务正在同步中...",
                task_list: "任务列表",
                add_task: "创建任务",

                // 列表字段
                index: "序号",
                name: "任务名称",
                type: "任务类型",
                duration: "任务时长",
                irrigate_duration: "灌溉时长(单区)",
                status: "任务状态",
                operation: "操作",

                timing: "混肥-定时灌溉",
                integral: "混肥-光积分灌溉",
                fertilizer: "储存罐-配肥",
                storage_timing: "储存罐-定时灌溉",
                storage_integral: "储存罐-光积分灌溉",
                channel_timing: "五通道混肥-定时灌溉",

                trigger: "立即灌溉",
                edit: "编辑",
                edit_time: "编辑时间",
                delete: "删除",
                delete_confirm: "确认删除该任务？",
                copy: "复制",
                trigger_success: "触发成功",
                trigger_failed: "触发失败",
                enabled_failed: "操作失败",
                // 有一个任务存在冲突
                enabled_conflict: "与任务【{name}】存在冲突,冲突时间【{time}】",
                self_conflict: "任务时间存在冲突,冲突时间【{time}】",
                max_should_be_greater_than_min: "最大值应大于最小值",
                min_should_be_less_than_max: "最小值应小于最大值",
                time_should_be_in_range: "时间应在光积分时段之间",

                // 弹窗内容
                dialog_edit_title: "编辑任务",
                dialog_add_title: "创建任务",
                dialog_copy_title: "复制任务",
                per_duration: "单次执行时长",
                name_placeholder: "请输入任务名称",
                // 定时灌溉
                timing_params: "定时灌溉参数",
                time: "执行时间(每日)",
                time_placeholder: "请选择执行时间",
                select_time: "选择时间",
                duration_text: "{time}",
                irrigate_to_drainage: "灌溉至排液",
                minute: "分钟",
                second: "秒",
                // 公共参数
                common_params: "公共参数",
                target_ec: "目标EC",
                target_ec_placeholder: "请输入目标EC",
                ec_pump_speed_ratio: "EC泵转速比",
                ec_pump_speed_ratio_full: "B泵转速/A泵转速",
                ec_pump_speed_ratio_placeholder: "请输入EC泵速比",
                ph_pump_speed: "pH泵转速",
                ph_pump_speed_placeholder: "请输入pH泵转速",
                ph_pump_speed_full: "",
                irrigate_area: "轮灌区域",
                irrigate_area_full: "",
                io_text: "{io}号阀",
                add_io: "添加",
                mode: "模式",
                pump_speed: "{name}泵转速",
                pump_speed_placeholder: "请输入{name}泵转速",

                // 轮灌间隔
                irrigate_interval: "轮灌间隔",
                // 积分灌溉
                integral_params: "积分灌溉参数",
                integral_duration: "光积分时段",
                integral_duration_text: "至",
                integral_threshold: "光积分阈值",
                integral_threshold_placeholder: "请输入光积分阈值",
                min_integral_interval: "两次灌溉最小间隔",
                max_integral_interval: "两次灌溉最大间隔",
                instant_integral: "光合有效辐射",
                instant_integral_placeholder: "请输入光合有效辐射",
                // 积分灌溉 - 首次灌溉
                first_irrigate_ec: "首次灌溉目标EC",
                first_irrigate_ec_placeholder: "请输入首次灌溉目标EC",
                first_irrigate_duration: "首次灌溉时长",
                first_irrigate_duration_placeholder: "请输入首次灌溉时长",
                enable_first_irrigate: "启用首次灌溉",
                trigger_mode: "触发方式",
                timing_trigger: "定时触发",
                timing_trigger_tip: "“光积分时段”的开始时间",
                integral_trigger: "光积分触发",
                integral_trigger_tip: "上一次灌溉结束后累积的光积分",
                first_integral_threshold: "首次光积分阈值",
                first_latest_trigger_time: "首次最迟触发时间",
                placeholder: "请完善{field}",
                first_latest_trigger_time_tip: "设定值需在光积分时段内",
                duration_type: "时长计算方式",
                quantitative: "定量",
                integral_to_irrigate_duration: "光积分转灌溉时长",
                soil_water_feedback: "土壤含水量反馈",
                soil_water_feedback_tip: "(轮灌阀区的第一个区必须设置为传感器土壤叉子所在的区域)",
                min_irrigate_duration: "最小灌溉时长",
                max_irrigate_duration: "最大灌溉时长",
                conversion_factor: "转化系数",
                soil_water_threshold: "含水率阈值",
                // 最大间隔失效时间
                max_interval_invalid_time: "最大间隔失效时间",
                max_interval_invalid_time_tip:
                    "比如光积分时段设置为8:00到15:00，最大间隔失效时间设置为13:00，那么13点后最大间隔不会触发灌溉",
                // 高级功能
                advanced: "高级功能",
                min_target_ec: "目标排液EC下限",
                max_target_ec: "目标排液EC上限",
                debug_extend: "调试拓展",
                // 配肥
                fertilizer_params: "配肥参数",
                fertilizer_type: "配肥类型",
                timing_fertilizer: "定时配肥",
                fertilizer_time: "配肥时间(每日)",
                fertilizer_duration: "配肥时长",
                fertilizer_duration_placeholder: "请选择配肥时长",
                liquid_level_fertilizer: "液位配肥",
                start_liquid_level: "启动配肥液位",
                stop_liquid_level: "停止配肥液位",
                meter: "米",

                // 计算灌溉时长
                calculate_irrigate_duration: "计算灌溉时长",
                irrigate_volume: "灌溉量",
                irrigate_volume_placeholder: "请输入灌溉量",
                dripper_rate: "滴头速率",
                dripper_rate_placeholder: "请输入滴头速率",
                irrigate_volume_unit: "mL/盆",
                dripper_rate_unit: "L/h/盆",
            },
        },
        conf: {
            group: "节点分组",
            create_node: "创建节点",
            create_dashboard: "创建园区",
            group_manage: "分组管理",
            permissions: "权限授予",
            edit_dashboard: "编辑园区",
            delete_dashboard: "删除园区",
            no_node: "当前分组没有任何节点",
            duplicate_group_names: "重复的分组名",
            dashboard_switch: "园区切换",

            total: "(<b>{total}</b>个节点)",
            search_placeholder: "输入园区名称进行搜索",
        },
        manage: {
            title: "园区配置",
        },
        // 公共分组组件
        group: {
            add: "添加",
            search_placeholder: "输入分组名称进行搜索",
            title_placeholder: "请输入分组名称",
            title: "分组名",
            operation: "操作",
        },
        // 节点设备类型
        type: {
            sensor: "传感器",
            controller: "控制器",
            fertigation: "水肥机",
            drainage: "排灌秤",
            other: "其他",
            scale: "电子秤",
        },
    },
};
