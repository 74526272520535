export default {
    // 目录
    nav: {
        home: "Home",

        overview: "Overview",
        overview_management: "Management",
        overview_park_detail: "Dashboard Detail",
        overview_map: "Mapping",
        overview_dataview: "Dataview",
        overview_dashboard: "Dashboard",
        overview_device: "Device",

        sensor: "GreenGuardian",
        sensor_basic: "Sensor",
        sensor_control: "Controller",
        sensor_prop: "Readings",
        sensor_task: "Task",
        sensor_irrigate: "Irrigation",

        aigrow: "AI Grow",
        aigrow_conf: "Park Configuration",
        aigrow_gardens: "Orchards",
        aigrow_ripen: "Ripen",
        aigrow_stomata_camera: "Stomata",

        erp: "ERP",
        es: "Scales",
        fertilizer: "Fertilizer",

        enterprise: "Enterprise",
        enterprise_member: "Members",
        enterprise_role: "Roles",
        enterprise_log: "User Logs",
        enterprise_permission: "Permissions",

        insights: "Configuration",
        insights_controller: "Controller",
        insights_node: "Node",
        insights_template: "Template",
    },
    // 头部
    header: {
        change_organization: "Change Organization",
        message: "Message",
        doc: "Documentation",
        help: "Help",
        profile_settings: "Profile",
        logout: "Logout",
        current: "Current",
        country: "Timezone",

        support: {
            title: "Support",
            before: "Pre Sales",
            after: "After Sales",
            tech: "Technical Support",
        },

        node: {
            dashboard: "Garden",
        },
    },
    // 鉴权与404等
    system: {
        message: {
            has_no_right: "You have no right to access this page.",
            back_to_previous_page: "Back to previous page",
            page_not_found: "Page not found",
            back_to_home: "GO HOME",
            upload_success: "Upload successfully",
            copy_success: "Copy successfully",
            del_success: "Deleted successfully",
            success: "Submitted successfully",
        },
        messagebox: {
            title: "Message",
            confirm: "Confirm",
            cancel: "Cancel",
            reset: "Reset",
            delete: "Delete",
            close: "Close",
            save: "Save",
            print: "Print",
            download: "Download",
        },
        notify: {
            success_title: "Success",
            error_title: "Error",
            title: "Action",
        },
        form: {
            select_is_required: " is required",
            field_is_required: " is required",
            field_is_not_valid: " is not valid",
            field_is_out_of_range: " is out of range",
            update_successfully: " update successfully",
            search_placeholder: "Please input keywords",
            del: "Confirm deletion of the record?",
        },
    },
};
