import { i18n } from "@/locale";
const {
    global: { t },
} = i18n;

export default [
    {
        path: "/fertilizer",
        name: "fertilizer",
        component: () => import("@/views/erp/Index.vue"),
        redirect: {
            name: "fertilizer-index",
        },
        meta: {
            title: "配肥助手 - ERP",
            requiredAuth: false,
            showInNav: true,
            // slug: "nav_fertilizer",
            name: t("nav.fertilizer"),
            icon: "beaker",
            activeMenu: "erp",
            // permissions: ["nav_fertilizer"],
        },
        children: [
            {
                path: "",
                name: "fertilizer-index",
                component: () => import("@/views/erp/fertilizer/Fertilizer.vue"),
                meta: {
                    title: "配肥助手 - ERP",
                },
            },
            {
                path: "recipe/:id(\\d+)?/detail",
                name: "fertilizer-detail",
                component: () => import("@/views/erp/fertilizer/Detail.vue"),
                meta: {
                    title: "配方 - ERP",
                    permissions: ["get_fertilizer_recipe"],
                },
            },
            {
                path: "recipe/:id(\\d+)?/edit",
                name: "fertilizer-edit",
                component: () => import("@/views/erp/fertilizer/Edit.vue"),
                meta: {
                    title: "配方编辑 - ERP",
                },
            },
        ],
    },
];
