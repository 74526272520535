export default {
    route_name: {
        "user-profile": "Profile",
        "user-organization": "Organization",
        "user-security": "Security Center",
        NotAuthenticated: "Not Authenticated",

        // sensor
        "sensor-basic": "Basic",
        "sensor-timelapse": "Timelapse",
        "sensor-task": "Task",
        "sensor-control": "Controller",
        "sensor-prop": "Prop",
        "sensor-chart": "Chart",
        "sensor-irrigate": "Irrigate",

        // es
        "salary-manage-stat": "Scales Stat",
        "salary-manage-detail": "Scales Detail",
        "salary-manage-setting": "Scales Setting",
        "salary-manage-account": "Scales Account",

        // overview
        "dashboard-management": "Dashboard Management",
        map: "Map",
        dataview: "Dataview",
        dashboard: "Dashboard",
        "dashboard-list": "Dashboard",
        "dashboard-detail": "Dashboard Detail",

        // insights
        "insights-controller": "Controller",
        "insights-node": "Node",
        "insights-template": "Template",

        // help
        "help-list": "Help List",
        "help-detail": "Doc Detail",

        // enterprise
        "enterprise-manage-member": "Members",
        "enterprise-user-log": "User Logs",

        // aigrow
        "aigrow-gardens": "Orchards",
        "aigrow-ripen-upload": "Upload",
        "aigrow-stomata": "Stomata",
        "aigrow-ripen-albums": "Albums",
        "aigrow-ripen-album": "Album",
        "aigrow-ripen-photo": "Photo",
        "aigrow-ripen-stack": "Stack",
        "aigrow-ripen-stack-append": "Stack Append",

        // account
        "account-login": "Login",

        // fertilizer
        "fertilizer-index": "Fertilizer",
        "fertilizer-detail": "Detail",
        "fertilizer-edit": "Edit",
    },
};
