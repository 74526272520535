export default {
    // 企业管理
    enterprise: {
        manage: {
            title: "Enterprise Management",
        },
        member: {
            title: "Member",
            select_member: "Select Member",

            // 搜索
            add_sub_account: "Create Account",
            search_label: "Search",
            search_placeholder: "Please input keywords",

            // 列表
            table_number: "ID",
            table_name: "Name",
            table_accountName: "Account",
            table_phone: "Phone",
            table_role: "Role",
            table_action: "Operation",
            role: "Role",
            permission: "Permission",
            assign_role: "Assigning Roles",
            assign_role_successfully: "Role assigned successfully",
            creator: "Creator",

            // 删除
            remove: "Remove",
            confirm_remove: "Are you sure to remove this account?",
            remove_successfully: "The Account removed successfully",

            // 表单
            sub_account: "Sub Account",
            add: "Add",
            edit: "Edit",
            type_phone: "Phone",
            type_account: "Account",
            phone_tip: "The phone format is incorrect",

            new_member_password_tip: "The password of new member is ",

            管理员: "Administrator",
            total_user: "Total <b>{total}</b> accounts",
        },
        role: {
            title: "Role",
            add_role: "Create Role",
            search_label: "Search",
            search_placeholder: "Please input keywords",
            total_role: "Total <b>{total}</b> roles",

            table_role: "Role",
            table_action: "Operation",
            table_created: "Created Time",
            table_updated: "Updated Time",

            remove_successfully: "The Role removed successfully",
            remove: "Remove",
            confirm_remove: "Are you sure to remove this account?",

            add: "Add",
            edit: "Edit",
            permission: "Permission",
        },
        permission: {
            all: "Select All",
            total_permission: "{total} permissions selected",
            authorization_successfully: "Authorization successful",
            assign_permission: "Assigning Permissions",
        },
        logs: {
            user: "User",
            time_range: "Time limit",
            start_time: "Select start time",
            end_time: "Select end time",
            app: "Application",
            app_placeholder: "Enter application name",
            resource: "Resource",
            resource_placeholder: "Enter resource name",
            resource_id: "Resource ID",
            resource_id_placeholder: "Enter Resource ID",
            action: "Action",
            action_placeholder: "Enter the name of the action",
            search: "Search",
            search_placeholder: "Retrieve from IP/User Agent (UA)/Description/Path",
            desc: "Description",
            time: "Time",
            ip: "IP address",
            dashboard_node: "Dashboard / Node",
        },
    },
};
