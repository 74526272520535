/**
 * @desc 旧页面地址兼容
 */

export default [
    // 旧版登录
    {
        path: "/login",
        redirect: "/account/login",
    },
    // 账号管理
    {
        path: "/account/management",
        redirect: "/enterprise/member",
    },
    // 资料设置
    {
        path: "/users/settings",
        redirect: "/user/profile",
    },
    // 组织切换
    {
        path: "/account/switch",
        redirect: "/user/organization",
    },
];
