export default {
    // 企业管理
    enterprise: {
        manage: {
            title: "企业管理",
        },
        member: {
            title: "成员管理",
            select_member: "选择成员",

            // 搜索
            add_sub_account: "添加账号",
            search_label: "搜索",
            search_placeholder: "支持账号/姓名/ID搜索",

            // 列表
            table_number: "序号",
            table_name: "姓名",
            table_accountName: "账号",
            table_phone: "手机号码",
            table_role: "用户角色",
            table_action: "操作",
            role: "角色",
            permission: "权限",
            assign_role: "分配角色",
            assign_role_successfully: "角色分配成功",
            creator: "创建者",

            // 删除
            remove: "移除",
            confirm_remove: "您确定要删除? 此操作无法撤消。",
            remove_successfully: "子账号移除成功",

            // 表单
            sub_account: "子账号",
            add: "添加",
            edit: "编辑",
            type_phone: "手机",
            type_account: "用户名",
            phone_tip: "手机格式不正确",

            new_member_password_tip: "初始密码为",

            管理员: "管理员",
            total_user: "当前共计 <b>{total}</b> 个账户",
        },
        role: {
            title: "角色管理",
            add_role: "添加角色",
            search_label: "搜索",
            search_placeholder: "支持角色搜索",
            total_role: "当前共计 <b>{total}</b> 个角色",

            table_role: "角色",
            table_action: "操作",
            table_created: "创建时间",
            table_updated: "更新时间",

            remove_successfully: "角色移除成功",
            remove: "移除",
            confirm_remove: "您确定要删除? 此操作无法撤消。",

            add: "添加",
            edit: "编辑",
            permission: "权限",
        },
        permission: {
            all: "全选",
            total_permission: "已选 {total} 个权限",
            authorization_successfully: "授权成功",
            assign_permission: "分配权限",
        },
        logs: {
            user: "用户",
            time_range: "时间范围",
            start_time: "选择开始时间",
            end_time: "选择结束时间",
            app: "应用",
            app_placeholder: "输入应用名称",
            resource: "资源",
            resource_placeholder: "输入资源代称",
            resource_id: "资源ID",
            resource_id_placeholder: "输入资源ID",
            action: "动作",
            action_placeholder: "输入动作代称",
            search: "搜索",
            search_placeholder: "从IP／用户代理(UA)／描述／路径中检索",
            desc: "描述",
            time: "时间",
            ip: "IP地址",
            dashboard_node: "园区 / 节点",
        },
    },
};
