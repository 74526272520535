export default {
    // 薪资管理
    salary: {
        stat: {
            title: "Summary",
        },
        detail: {
            title: "Detail",
        },
        setting: {
            title: "Setting",
        },
        account: {
            title: "Account",
        },
        manage: {
            title: "Scales",
            salary_summary: "Summary",
            salary_detail: "Detail",
            salary_setting: "Setting",
            pick_account: "Account",
            cloud_scale_manage: "Cloud Scale Manage",
            property_manage: "Property Manage",
            export: "Export",
            base_salary: "Base Configuration",
            base_salary_success: "Base Configuration updated successfully",
            base: "Base salary",
            rmb: "RMB",
            piecework: "Commission Configuration",
            piecework_success: "Commission Configuration updated successfully",
            proportion: "Percentage of commission",
            unit: "RMB/kg",
            scale: "Scale Configuration",
            add_scale: "Add",
            add_scale_success: "Scale Configuration updated successfully",
            edit_scale: "Edit",
            scale_logs: "Logs",
            add_wage: "Edit extra",
            import: "Import completed",
            import_success: "Import successfully",
            import_total: "Total",
            import_fail: "Import failed",
            export_success: "Export successfully",
            all_dashboards: "All Dashboards",
            data_chart: "Data Chart",
            dashboard: "Dashboard",
            to: "To",
            time: "Time",
            no: "Nothing",
            keyword: "Search Keyword",
            nodata: "No Data",
            num: "Harvest Quantity",
        },
        table: {
            date: "Date",
            name: "Name",
            phone: "Phone Number",
            piecework: "Piecework (g)",
            piece_rate: "Piece Rate (RMB)",
            base_wage: "Base Wage (RMB)",
            additional_wages: "Additional Wages (RMB)",
            wages: "Total wages (RMB)",
            remark: "Remark",
            id: "ID",
            group: "Group",
            action: "Operation",
            edit: "Edit",
            total: "Total size (g)",
            total_salary: "Total salary (RMB)",
            scales: "Electronic Scale ID",
            logs: "Operation Log",
            user: "Operator",
            value: "Wages (RMB)",
        },
        form: {
            group: "Sorting group",
            id: "Electronic Scale ID",
            message_group: "Please enter the name of the sorting group",
            message_id: "Please fill in the electronic scale ID",
        },
    },
    // 临时工管理
    workers: {
        manage: {
            title: "Workers",
            export: "Import Sheet",
            add: "New Account",
            edit: "Edit",
            template: "Download Excel Template",
            fail: "Import failed",
            edit_tips: "Whether to make changes to employee information?",
        },
        table: {
            name: "Name",
            phone: "Phone Number",
            id: "ID Card",
            action: "Operation",
            edit: "Edit",
            card: "Bank Card",
            bank: "Bank",
            number: "Work Number",
            message_name: "Please enter your name",
            message_id: "Please fill in your ID number",
            message_phone: "Please fill in the cell phone number",
            message_bankName: "Please fill in the name of the bank",
            message_cardNo: "Please fill in the bank card number",
        },
    },
};
