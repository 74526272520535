export default {
    fertilizer: {
        recipe_receive: "Receive",
        recipe_making: "Making",
        add_recipe: "Add Recipe",
        search_placeholder: "Enter keywords and press enter to search",
        recipe_content: "Recipe Content",

        title: "Title",
        edit_title: "Edit Title",
        description: "Description",
        remark: "Remark",
        meta: "Meta",
        status: "Status",
        operation: "Operation",
        view: "View",
        make: "Make",
        edit: "Edit",
        delete: "Delete",
        more: "More",
        edit_remark: "Edit Remark",
        delete_recipe_confirm: "Delete Recipe Confirm",
        placeholder: "Please enter the {field}",
        maker: "Maker",
        expired: "Expired",

        is_public: "Public",
        public: "Public",
        assign: "Assign",
        add_user: "Add User",

        updated_at: "Updated At",
        created_at: "Created At",

        tabs: {
            desc: "User Guide",
            meta: "Cautions",
            recipients: "Authorization",
        },

        add_dialog: {
            title_required: "Please enter the title",
        },

        add_caution: "Add Caution",
        caution_key: "Key",
        caution_value: "Val",
        can_not_be_empty: "{field} can not be empty",

        expired_at: "Expired",
        confirm_remove_user: "Confirm remove this user?",
        expired_at_placeholder: "Please select the access expired date",
        expired_at_success: "Expired date updated successfully",
        recipients: "Recipients",
    },
};
