export default {
    // 智能果园
    aigrow: {
        title: "Ai Grow",
        gardens: {
            title: "Park overview",
        },
        overview: {
            add: "New orchard",
            edit: "Edit orchard",
            group_manage: "Group management",
            name: "Name",
            placeholder_name: "Please enter a name",
            group: "Group",
            select_group: "Please select a group",
            sort: "Sort",
            placeholder_sort: "Please enter sort",
            remark: "Remark",
            placeholder_remark: "Please enter remark",
            no_group: "Unclassified",
            only_edit: "Edit",
            only_delete: "Delete",
            untitled: "Unnamed",
            update_time: "Update time",
            no_match: "The current group has no orchard assigned",
            setting: "Setting",
            tips: "Tips",
            confirm_delete: "Are you sure to delete the orchard (this operation cannot be undone)?",
            enter_group_name: "Please enter a group name",
            group_name_error: "Group name cannot be empty",
            duplicate_group_names: "Duplicate group name",
            del_message: "Are you sure you want to delete? This action cannot be undone.",
            sort_btn_start: "Start Order",
            sort_btn_stop: "Stop Order",
            empty_garden: "No orchard currently exists",
            stat: "Total {albums} albums, {photos} photos",

            // overview
            completed: "Completed",
            queue: "In queue",
            processing: "Processing",
            today_process: "Dealt with today",
            yest_process: "Dealt with yesterday",
            month_process: "Processing this month",
            zhang: "photos",
            buy: "Purchased model:",
            num: "Quota used:",
        },
        upload: {
            drop_file: "Drop files here or",
            click_upload: "click to upload",
            file_tip: "Upload up to {limit} images at a time, and each image size is no more than {max} MB",

            start_upload: "Upload",
            select_album: "Album",
            select_analyzer: "Analyzer Version",
            select_model: "Model",
            reset: "Reset",
            over_limit: "Exceeds the maximum upload limit",
            album_required: "Please select an album",
            analyzer_required: "Please select an analyzer version",
            start_analyze: "Start Analyze",
            cache_tip: "You have unfinished tasks, continue?",
            continue: "Continue",
            upload_finish_tips:
                "Upload is ready, click the button below to start the analysis, or you can continue to add more pictures and submit after completion.",
            over_size: "Exceeds the maximum upload size limit",
            // 超出大小限制的文件已自动移除
            over_size_msg: "The file that exceeds the maximum upload size limit has been removed automatically",

            model_list: {
                raspberry: "树莓",
                blueberry: "蓝莓",
            },
        },
        ripen: {
            title: "Ripen",
            process_finish: "A photo is analyzed",
            to_view: "View",
            notification_title: "【AI Grow】You have a new photo analyzed",
            album: "Album",
            photo: "Photo",
        },
        albums: {
            garden_list: "Orchard List",
            garden_album: "Orchard Album",
            no_garden_albums: "There is no album for this orchard, please bind the orchard in the album first.",
            title: "All albums",
            add: "New Album",
            edit: "Edit Album",
            setting: "Setting",
            tips: "Tips",
            default_album: "Default Icon",
            update_time: "Update",
            create_time: "Create",
            upload: "Upload Image",
            desc: "Descend",
            asc: "Ascend ",
            sticky: "Sticky",
            unsticky: "Unsticky",
            /**
             * 新增编辑表单
             */
            confirm_delete: "Are you sure to delete the album (this operation cannot be undone)?",
            only_edit: "Edit",
            only_delete: "Delete",
            untitled: "Unnamed",
            description: "Description",
            no_description: "No Description",
            placeholder_description: "Please enter description",
            name: "Name",
            placeholder_name: "Please enter name",
            icon: "Icon",
            select_icon: "Please select icon",
            /**
             * 单页
             */
            frozen: "Freeze",
            thaw: "Thaw",
            frozen_tips: "After freezing, you cannot append pictures to this album",
            is_frozen: "Frozen Album",
            confirm: "Confirm",
            cancel: "Cancel",
            save: "Save",
            add_param: "Add Parameter",
            edit_param: "Edit Parameter",
            key: "Key",
            val: "Value",
            remark: "Remark",
            placeholder_key: "Please enter key",
            placeholder_val: "Please enter value",
            placeholder_remark: "Please enter remark",
            placeholder_remark_update: "Please enter a note whose key is {key}",
            no_data: "No Data",
            param_config_tip: "Please configure the album parameters first",
            param_select_tip: "Please select a parameter first",
            album_param: "Album Parameters",
            associated_garden: "Associated Orchard",
            untie: "Untie",
            untie_tips: "Are you sure to unbind?",
            associated_dashboard: "Associated Dashboard",
            bind: "Bind",
            select_dashboard: "Please select a dashboard",
            select_garden: "Please select a garden",

            album_chart: "Trending",
            batch_list: "Stacks",
            user_log: "History",
            line: "Sample Analysis",
            bar: "Production Forecast",

            records: " records",
            prev: "Previous",
            next: "Next",

            operate: "operation",
            user: "User",
            method: "Action",
            ip: "IP",
            ua: "User Agent",
            time: "Time",

            item: {
                creator: "Creator",
                default_desc: "",
                bind_garden: "Bind Orchard",
                bind_node: "Bind Node",
                detail: "View Details",
                default_name: "Anonymous",
            },

            chart: {
                actual: "Actual",
                predicted: "Predicted",
                analyzing: "Analyzing",
                actual_chart: "Actual Chart",
                predicted_chart: "Predicted Chart",
                all: "All",
                start: "Start Date",
                end: "End Date",
                to: "To",
                last_7_days: "L7D",
                last_3_days: "L3D",
                yesterday: "Yesterday",
                today: "Today",
                last_month: "LM",
                last_3_months: "L3M",
                last_6_months: "L6M",
                last_24_hours: "L24H",
                total_output: "Total Output",
                harvesting_progress: "Harvesting Progress",
            },

            actions: {
                upload_photo: "Upload Photo",
                config_album: "Configure Album",
                create_album: "Create Album",
                update_album: "Update Album",
                delete_album: "Delete Album",
                freeze_album: "Freeze Album",
                sticky_album: "Sticky Album",
                bind_garden: "Bind Orchard",
                unbind_garden: "Unbind Orchard",
                bind_node: "Bind Node",
                unbind_node: "Unbind Node",
                edit_photo: "Edit Photo",
                delete_photo: "Delete Photo",
                config_stack: "Configure Stack",
                update_stack: "Update Stack",
                delete_stack: "Delete Stack",
            },
        },
        photo: {
            all: "All",
            un_name: "Unnamed",
            remark: "Remark",
            uploaded_at: "Upload At",
            remark_placeholder: "Please enter a remark",
            // 是否参与计算
            is_analyze: "Participate",
            // 当不参与计算时，该图片数据不会被计入到总数据分析中
            participate_tip:
                "When not participating, the data of this picture will not be included in the total data analysis",
            del_confirm: "Are you sure you want to delete? This action cannot be undone.",
            // 重新分析
            redo: "Re-analyze",

            before: "Before",
            after: "After",
            source: "Source",
            processed: "Processed",
            all_photo: "All",
            processing: "Processing...",
            total_photos: "Total {count} photos",

            bar: "Bar",
            pie: "Pie",
            pie_name: "Analysis",

            // 表格
            type: "Type",
            count: "Count",
            amend: "Amend",

            process_status: {
                unclaimed: "Waiting",
                published: "Published",
                claimed: "Queued", //已领取
                assigned: "Assigned",
                queued: "Queued",
                processing_started: "Processing Started",
                processing: "Processing",
                in_review: "In Review",
                awaiting_publishing: "Awaiting Publishing",
                reported: "Reported.",
                completed: "Rendering, please do not close the window.",
            },

            // 果实状态
            ripen_status: {
                matured: "Matured",
                intermediate: "Intermediate",
                flower: "Flower",
                flowerbud: "Flowerbud",
                harvested: "Harvested",
            },
        },
        params: {
            area: "Area",
            row_spacing: "Row Spacing",
            plant_spacing: "Plant Spacing",
            marker_spacing: "Marker Spacing",
            number_of_rows: "Rows",
            row_length: "Row Length",
            k_intermediate: "Uncollected coefficient",
            k_flowers: "Flower quantity coefficient",
            pick: "Pick",
        },
        stack: {
            move: "Move",
            append: "Append",
            move_stack: "Batch Move",
            select_album: "Please select an album to move to",
            enter_keyword: "Please enter a album name",
            move_message: "Are you sure you want to move this batch to a new album?",
            pie: "Pie",
            photo_list: "Picture list",
            intervention: "Intervention",
            info: "Batch information",
            batch: "Batch",
            upload: " upload",
            single: "Analysis",
            limit: "Please enter 1-50 characters",
            photo: "Photo",
            all: "All",
            total: "Total",
            average: "Average",
            filming_time: "Shooting Time",
            created_at: "Uploaded at",
            select_filming_time: "Please set the shooting time",
            select_filming_date: "Please select a shooting date",
            save: "Save",
            remark: "Remark",
            creator: "Creator",
            operate: "More",
        },
    },
};
